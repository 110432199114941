import React from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GroupIcon from "@material-ui/icons/Group";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import PageContainer from "../../components/PageContainer";
import { useAppBarModel } from "../../utils/appBarContext";

import { queryGetUserAndListOrgs } from "./UserHelpers";
import {
  getUserAndListOrgs,
  getUserAndListOrgsVariables,
} from "./gentypes/getUserAndListOrgs";
import UserAdd from "./UserAdd";

// import UserAdd from "./UserAdd";

const ViewUser: React.FC = () => {
  const appBarModel = useAppBarModel();

  const { loading, error, data } = useQuery<
    getUserAndListOrgs,
    getUserAndListOrgsVariables
  >(queryGetUserAndListOrgs, {
    variables: {
      range: { first: 10 },
    },
  });

  const history = useHistory();

  const menuItems = React.useMemo(() => {
    return [
      {
        id: "add",
        name: "Add Organization",
        help: "Create new organization",
        icon: <AddIcon />,
        onInvoke: () => history.push(`/org/add`),
        fab: true,
      },
      {
        id: "edit",
        name: "Edit User",
        help: "Edit user",
        icon: <EditIcon />,
        onInvoke: () => history.push(`/user/edit`),
      },
      {
        id: "delete",
        name: "Delete User",
        help: "Delete user",
        icon: <DeleteIcon />,
        onInvoke: () => history.push(`/user/delete`),
      },
    ];
  }, [history]);

  React.useEffect(() => {
    // Call setTitle within useEffect to avoid updates during render.
    // See "Warning: Cannot update a component from inside the function body of a different component."
    // in https://reactjs.org/blog/2020/02/26/react-v16.13.0.html
    const fullname = data?.getUser?.fullname;
    if (fullname) {
      appBarModel.setTitle(`Hello ${fullname}`);
    }
  }, [appBarModel, data]);

  const user = data?.getUser;
  const listData = data?.listMemberOrgsForUser?.edges;

  // TODO: Navigate to UserAdd
  if (error) return <UserAdd />;

  return (
    <PageContainer loading={loading} error={error} menuItems={menuItems}>
      {!user ? (
        []
      ) : (
        <List>
          {listData?.map(({ node }, index) => {
            return (
              <ListItem
                key={node.id}
                button
                divider={index + 1 !== listData.length}
                onClick={() => history.push(`/org/${node.id}/get`)}>
                <ListItemAvatar>
                  <Avatar>
                    <GroupIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={node.title} secondary={node.desc} />
                <ArrowForwardIosIcon />
              </ListItem>
            );
          })}
        </List>
      )}
    </PageContainer>
  );
};

export default ViewUser;
