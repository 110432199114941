import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import GroupForm, { GroupFormData } from "./GroupForm";
import {
  queryListGroups,
  mutationAddGroup,
  validationSchemaGroupInput,
} from "./GroupHelpers";
import { listGroups, listGroups_listGroups_edges } from "./gentypes/listGroups";
import { addGroup, addGroupVariables } from "./gentypes/addGroup";
import { OrgPageProps } from "../Org/OrgHelpers";

const defaultGroupInput: GroupFormData = {
  title: "",
  desc: "",
};

const GroupAdd: React.FC = () => {
  const history = useHistory();
  const { orgId } = useParams<OrgPageProps>();

  const [doMutation] = useMutation<addGroup, addGroupVariables>(
    mutationAddGroup
  );

  const onSubmit = React.useCallback(
    (values: GroupFormData, actions: FormikHelpers<GroupFormData>) => {
      doMutation({
        variables: {
          orgId: orgId!,
          group: {
            title: values.title,
            desc: values.desc ? values.desc : undefined,
          },
        },
        update: (cache, { data }) => {
          const readData = cache.readQuery<listGroups>({
            query: queryListGroups,
            variables: {
              orgId: orgId!,
            },
          });

          // Only add to query if data is present readQuery may throw
          const queryData = readData!.listGroups;
          if (queryData) {
            const edges: listGroups_listGroups_edges[] = [
              {
                __typename: "GroupEdge",
                cursor: "",
                node: data!.addGroup!,
              },
              ...queryData!.edges!,
            ];

            const writeData: listGroups = {
              listGroups: {
                __typename: queryData.__typename,
                edges,
                pageInfo: { ...queryData.pageInfo },
              },
            };
            cache.writeQuery({
              query: queryListGroups,
              variables: {
                orgId: orgId!,
              },
              data: writeData,
            });
          }
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, orgId, history]
  );

  const onCancel = () => {
    history.push(`list`);
  };

  return (
    <Formik
      initialValues={defaultGroupInput}
      onSubmit={onSubmit}
      validationSchema={validationSchemaGroupInput}>
      {({ submitForm, isSubmitting }) => {
        return (
          <GroupForm
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            onCancel={onCancel}>
            {}
          </GroupForm>
        );
      }}
    </Formik>
  );
};

export default GroupAdd;
