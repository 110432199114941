import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PersonIcon from "@material-ui/icons/Person";

import PageContainer from "../../../components/PageContainer";
import { queryListMembersInGroup } from "./GroupMemberHelpers";
import {
  listMembersInGroup,
  listMembersInGroupVariables,
} from "./gentypes/listMembersInGroup";
import { GroupPageProps } from "../GroupHelpers";

const GroupMemberList: React.FC = () => {
  const history = useHistory();
  const { groupId } = useParams<GroupPageProps>();

  const { loading, error, data } = useQuery<
    listMembersInGroup,
    listMembersInGroupVariables
  >(queryListMembersInGroup, {
    variables: {
      groupId: groupId!,
    },
  });

  const menuItems = React.useMemo(() => {
    return [
      {
        id: "add",
        name: "Add Member",
        help: "Add member to group",
        icon: <AddIcon />,
        onInvoke: () => history.push(`add`),
        fab: true,
      },
    ];
  }, [history]);

  const listData = data?.listMembersInGroup?.edges;

  return (
    <PageContainer loading={loading} error={error} menuItems={menuItems}>
      <List>
        {listData?.map(({ node }, index) => {
          return (
            <ListItem
              key={node.id}
              button
              divider
              onClick={() => history.push(`${node.id}/get`)}>
              <ListItemAvatar>
                <Avatar>
                  <Badge color='secondary'>
                    <PersonIcon fontSize='large' />
                  </Badge>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={node.name} secondary={node.info} />
              <ArrowForwardIosIcon />
            </ListItem>
          );
        })}
      </List>
    </PageContainer>
  );
};

export default GroupMemberList;
