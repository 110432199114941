import React from "react";
import { useMutation } from "@apollo/client";
import Typography from "@material-ui/core/Typography";

import { mutationAddUser } from "./UserHelpers";
import { addUser, addUserVariables } from "./gentypes/addUser";

/*
const defaultUserInput: UserInput = {
  email: "",
  fullname: "",
  nickname: ""
};
*/

const UserAdd: React.FC = () => {
  // TODO: Either ask user or get from login data (see AddUser.js)
  const variables: addUserVariables = {
    user: {
      email: "testuser@teamilize.com",
      fullname: "Test user",
    },
  };
  const [doMutation, { error, data, loading }] = useMutation<
    addUser,
    addUserVariables
  >(mutationAddUser, {
    variables,
  });

  if (loading) return <Typography>"Loading..."</Typography>;
  if (error) return <Typography>`Error! ${error.message}`</Typography>;
  if (!data?.addUser) {
    doMutation();
    return <Typography>"Loading..."</Typography>;
  }

  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {data.addUser.email}
    </Typography>
  );
};

export default UserAdd;
