import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PersonIcon from "@material-ui/icons/Person";

import PageContainer from "../../components/PageContainer";
import { queryListMembers } from "./MemberHelpers";
import { listMembers, listMembersVariables } from "./gentypes/listMembers";
import { OrgPageProps } from "../Org/OrgHelpers";

const MemberList: React.FC = () => {
  const history = useHistory();
  const { orgId } = useParams<OrgPageProps>();

  const { loading, error, data } = useQuery<listMembers, listMembersVariables>(
    queryListMembers,
    {
      variables: {
        orgId: orgId!,
      },
    }
  );

  const menuItems = React.useMemo(() => {
    return [
      {
        id: "add",
        name: "Add Member",
        help: "Add member to organization",
        icon: <AddIcon />,
        onInvoke: () => history.push(`add`),
        fab: true,
      },
    ];
  }, [history]);

  const listData = data?.listMembers?.edges;

  return (
    <PageContainer loading={loading} error={error} menuItems={menuItems}>
      <List>
        {listData?.map(({ node }, index) => {
          return (
            <ListItem
              key={node.id}
              button
              divider={index !== listData.length - 1}
              onClick={() => history.push(`${node.id}/get`)}>
              <ListItemAvatar>
                <Avatar>
                  <Badge color='secondary'>
                    <PersonIcon fontSize='large' />
                  </Badge>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={node.name} secondary={node.info} />
              <ArrowForwardIosIcon />
            </ListItem>
          );
        })}
      </List>
    </PageContainer>
  );
};

export default MemberList;
