import gql from "graphql-tag";
import * as Yup from "yup";
import { pageInfoFragment } from "../CommonHelpers";
import { OrgPageProps } from "../Org/OrgHelpers";

export interface GroupPageProps extends OrgPageProps {
  groupId: string;
}

const groupFragment = gql`
  fragment GroupData on Group {
    id
    title
    desc
  }
`;

const groupListFragment = gql`
  fragment GroupListData on GroupConnection {
    edges {
      cursor
      node {
        ...GroupData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
  ${pageInfoFragment}
  ${groupFragment}
`;

export const queryListGroups = gql`
  query listGroups($orgId: ID!) {
    listGroups(orgId: $orgId) @connection(key: "groups", filter: ["orgId"]) {
      ...GroupListData
    }
  }
  ${groupListFragment}
`;

export const queryGetGroup = gql`
  query getGroup($id: ID!) {
    getGroup(id: $id) {
      ...GroupData
    }
  }
  ${groupFragment}
`;

export const mutationAddGroup = gql`
  mutation addGroup($orgId: ID!, $group: GroupInput!) {
    addGroup(orgId: $orgId, group: $group) {
      ...GroupData
    }
  }
  ${groupFragment}
`;

export const mutationEditGroup = gql`
  mutation editGroup($id: ID!, $group: GroupInput!) {
    editGroup(id: $id, group: $group) {
      ...GroupData
    }
  }
  ${groupFragment}
`;

export const queryGetGroupInfo = gql`
  query getGroupInfo($id: ID!) {
    getGroup(id: $id) {
      ...GroupData
    }
    getGroupInfo(id: $id) {
      id
      members {
        totalCount
        addCount
        addOn
        delCount
        delOn
      }
      topics {
        totalCount
        addCount
        addOn
        delCount
        delOn
      }
      events {
        totalCount
        addCount
        addOn
        delCount
        delOn
      }
      signups {
        totalCount
        addCount
        addOn
        delCount
        delOn
      }
    }
  }
  ${groupFragment}
`;

export const validationSchemaGroupInput = Yup.object().shape({
  title: Yup.string().max(256, "Too Long!").required("Required"),
  desc: Yup.string(),
});
