import React from "react";

import { BrowserRouter as Router } from "react-router-dom";
//import Fade from "@material-ui/core/Fade";

import ScrollToTop from "./ScrollToTop";

interface FadeTransitionRouterProps {
  children: React.ReactNode;
}

const FadeTransitionRouter: React.FC<FadeTransitionRouterProps> = ({
  children,
}) => {
  //const location = useLocation();
  return (
    <Router>
      <ScrollToTop>{children}</ScrollToTop>
    </Router>
  );
};

export default FadeTransitionRouter;
