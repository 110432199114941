/*
Root Component setups the following:
- Material-UI theme at the top level so Amplify Auth can pick it up
- Apollo Client to provide context to app component
- Amplify Auth to provide authentication 
*/

import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Amplify, Hub } from "aws-amplify";
import { ApolloProvider } from "@apollo/client";
import { withAuthenticator } from "@aws-amplify/ui-react";
import App from "./App";
import {
  amplifyConfig,
  apolloClient,
  federatedConfig,
  isLocalhost,
} from "../config/appConfig";
import theme from "../config/theme";

Amplify.configure(amplifyConfig);

const AppWithAuth = isLocalhost
  ? App
  : withAuthenticator(App, {
      federated: federatedConfig,
    });

const Root: React.FC = () => {
  React.useEffect(() => {
    const authListener = (data: any) => {
      if (data.payload.event === "signOut") {
        apolloClient.clearStore();
      }
    };
    Hub.listen("auth", authListener);

    return () => {
      Hub.remove("auth", authListener);
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <ApolloProvider client={apolloClient}>
          <AppWithAuth />
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default Root;
