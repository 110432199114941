import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";

import MemberForm, {
  GroupMemberFormData,
  MemberListData,
} from "./GroupMemberForm";
import {
  queryListMembersInGroup,
  mutationAddMemberToGroup,
  validationSchemaMemberInput,
} from "./GroupMemberHelpers";
import {
  addMemberToGroup,
  addMemberToGroupVariables,
} from "./gentypes/addMemberToGroup";
import {
  listMembersInGroup,
  listMembersInGroup_listMembersInGroup_edges,
} from "./gentypes/listMembersInGroup";

import { queryListMembers } from "../../Member/MemberHelpers";
import {
  listMembers,
  listMembersVariables,
} from "../../Member/gentypes/listMembers";
import { MemberRole } from "./../../../globalTypes.d";
import { GroupPageProps } from "../GroupHelpers";

export const defaultMemberInput: GroupMemberFormData = {
  id: "",
  role: MemberRole.MEMBER,
};

const GroupMemberAdd: React.FC = () => {
  const history = useHistory();
  const { orgId, groupId } = useParams<GroupPageProps>();

  const [doMutation] = useMutation<addMemberToGroup, addMemberToGroupVariables>(
    mutationAddMemberToGroup
  );

  const onSubmit = React.useCallback(
    (values, actions) => {
      doMutation({
        variables: {
          id: values.id,
          groupId: groupId!,
          access: { role: values.role },
        },
        update: (cache, { data }) => {
          const readData = cache.readQuery<listMembersInGroup>({
            query: queryListMembersInGroup,
            variables: {
              groupId: groupId!,
            },
          });

          // Only add to query if data is present readQuery may throw
          const queryData = readData!.listMembersInGroup;
          if (queryData) {
            const edges: listMembersInGroup_listMembersInGroup_edges[] = [
              {
                __typename: "MemberEdge",
                cursor: "",
                node: data!.addMemberToGroup!,
              },
              ...queryData!.edges!,
            ];

            const writeData: listMembersInGroup = {
              listMembersInGroup: {
                __typename: queryData.__typename,
                edges,
                pageInfo: { ...queryData.pageInfo },
              },
            };
            cache.writeQuery({
              query: queryListMembersInGroup,
              variables: {
                groupId: groupId!,
              },
              data: writeData,
            });
          }
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, groupId, history]
  );

  const { data } = useQuery<listMembers, listMembersVariables>(
    queryListMembers,
    {
      variables: {
        orgId: orgId!,
      },
    }
  );

  const memberData = React.useMemo<MemberListData[] | undefined>(() => {
    return data?.listMembers?.edges?.reduce<MemberListData[]>(
      (acc, { node }) => {
        const group = node.groups?.find((item) => item.groupId === groupId);
        // Add all members not part of this group
        if (!group) {
          acc.push({
            id: node.id,
            name: node.name,
            info: node.info,
            photoUrl: node.photoUrl,
          });
        }
        return acc;
      },
      []
    );
  }, [data, groupId]);

  if (!memberData) return null;

  const onCancel = () => {
    history.push(`list`);
  };

  return (
    <Formik
      initialValues={defaultMemberInput}
      onSubmit={onSubmit}
      validationSchema={validationSchemaMemberInput}>
      {({ submitForm, isSubmitting }) => {
        return (
          <MemberForm
            memberData={memberData}
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            onCancel={onCancel}>
            {}
          </MemberForm>
        );
      }}
    </Formik>
  );
};

export default GroupMemberAdd;
