import gql from "graphql-tag";
import * as Yup from "yup";
import { memberFragment, memberListFragment } from "../../Member/MemberHelpers";
import { GroupPageProps } from "../GroupHelpers";

export interface GroupMemberPageProps extends GroupPageProps {
  memberId: string;
}

export const queryListMembersInGroup = gql`
  query listMembersInGroup($groupId: ID!) {
    listMembersInGroup(groupId: $groupId)
      @connection(key: "members", filter: ["groupId"]) {
      ...MemberListData
    }
  }
  ${memberListFragment}
`;

export const mutationAddMemberToGroup = gql`
  mutation addMemberToGroup(
    $id: ID!
    $groupId: ID!
    $access: MemberAccessInput!
  ) {
    addMemberToGroup(id: $id, groupId: $groupId, access: $access) {
      ...MemberData
    }
  }
  ${memberFragment}
`;

export const mutationEditMemberAccessInGroup = gql`
  mutation editMemberAccessInGroup(
    $id: ID!
    $groupId: ID!
    $access: MemberAccessInput!
  ) {
    editMemberAccessInGroup(id: $id, groupId: $groupId, access: $access) {
      ...MemberData
    }
  }
  ${memberFragment}
`;

/*

    addMemberToGroup(
      id: ID!
      groupId: ID!
      groupMember: GroupMemberInput
      access: MemberAccessInput!
    ): Member!
    deleteMemberFromGroup(id: ID!, groupId: ID!): Member
    editMemberInGroup(
      id: ID!
      groupId: ID!
      groupMember: GroupMemberInput!
    ): Member!
    editMemberAccessInGroup(
      id: ID!
      groupId: ID!
      access: MemberAccessInput!
    ): Member!

*/

export const validationSchemaMemberInput = Yup.object().shape({
  id: Yup.string().max(256, "Too Long!").required("Required"),
});

export const memberRole = [
  { type: "BANNED", name: "Banned" },
  { type: "GUEST", name: "Guest" },
  { type: "MEMBER", name: "Member" },
  { type: "PARTICIPANT", name: "Participant" },
  { type: "LEADER", name: "Leader" },
  { type: "ADMIN", name: "Admin" },
  { type: "OWNER", name: "Owner" },
];

export const memberType = [
  { type: "ADULT", name: "Adult" },
  { type: "UNDER18", name: "Under 18" },
  { type: "UNDER13", name: "Under 13" },
];
