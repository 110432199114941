import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import MemberList from "./MemberList";
import MemberGet from "./MemberGet";
import MemberAdd from "./MemberAdd";
import MemberEdit from "./MemberEdit";

const Member: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/list`} component={MemberList} />
      <Route path={`${path}/add`} component={MemberAdd} />
      <Route path={`${path}/:memberId/get`} component={MemberGet} />
      <Route path={`${path}/:memberId/edit`} component={MemberEdit} />
    </Switch>
  );
};

export default Member;
