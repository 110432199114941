import gql from "graphql-tag";
import * as Yup from "yup";
import { OwnerPageProps, pageInfoFragment } from "../CommonHelpers";

export interface EventPageProps extends OwnerPageProps {
  eventId: string;
}

const eventFragment = gql`
  fragment EventData on Event {
    id
    title
    start
    end
    allDay
    location
    body
    addOn
    addBy
    editOn
    editBy
  }
`;

const eventListFragment = gql`
  fragment EventListData on EventConnection {
    edges {
      cursor
      node {
        ...EventData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
  ${pageInfoFragment}
  ${eventFragment}
`;

export const queryListEvents = gql`
  query listEvents($ownerId: ID!, $range: PaginationInput) {
    listEvents(ownerId: $ownerId, range: $range)
      @connection(key: "events", filter: ["ownerId"]) {
      ...EventListData
    }
  }
  ${eventListFragment}
`;

export const queryGetEvent = gql`
  query getEvent($id: ID!) {
    getEvent(id: $id) {
      ...EventData
    }
  }
  ${eventFragment}
`;

export const mutationAddEvent = gql`
  mutation addEvent($ownerId: ID!, $event: EventInput!) {
    addEvent(ownerId: $ownerId, event: $event) {
      ...EventData
    }
  }
  ${eventFragment}
`;

export const mutationEditEvent = gql`
  mutation editEvent($id: ID!, $event: EventInput!) {
    editEvent(id: $id, event: $event) {
      ...EventData
    }
  }
  ${eventFragment}
`;

export const validationSchemaEventInput = Yup.object().shape({
  title: Yup.string().max(256).required(),
  body: Yup.string().max(1024),
});
