import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";

import { Auth } from "aws-amplify";

import { AppMenuItem } from "../utils/appBarContext";

const useStyles = makeStyles((theme) => ({
  grow: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    margin: theme.spacing(0, 1, 0, 1),
    flexGrow: 1,
    flexShrink: 1,
  },
  toolbar: {
    flexDirection: "row",
    padding: 0,
  },
  moreButton: {
    flexShrink: 0,
  },
}));

/*
Properties:
- title - from navigation params (two lines?)
- back button - navigate previous
- menu button - items: logout
*/
type TitleChanged = (title: string) => void;
type MenuItemsChanged = (menuItems?: AppMenuItem[]) => void;

export interface HeaderNotifier {
  titleChanged: TitleChanged;
  menuItemsChanged: MenuItemsChanged;
}

interface HeaderToolbarProps {
  notifier: HeaderNotifier;
}

const HeaderToolbar: React.FC<HeaderToolbarProps> = ({ notifier }) => {
  const classes = useStyles();

  // Reminder: Keep header as simple and clean as possible.
  // TODO: Add icon and subtitle to title bar
  // TODO: Add api for nav panel
  // TODO: Add pressed (and close) state to menu buttons
  // TODO: Maybe pull out profile/user button (make it easy to switch users)
  // TODO: Determine how to handle Nav.  Should we just have a back button w/ description
  //    or home burger.
  // TODO: Determine if title should be a button and what should it do?
  //    Like have a breadcrumb bar, favorites/frequent/recent, group/org menu, jump to page top/bottom
  //    Like browser
  // TODO: Determine where search should go?  On appbar (global or scoped)?  Each page (scoped)?
  //    Under appbar (only for lists)?
  // Global menu items:
  //   - search
  //   - profile (user)
  //   - feedback
  //   - help/support
  //   - tips

  const [title, setTitle] = React.useState<string>("Teamilize");
  const [menuItems, setMenuItems] = React.useState<AppMenuItem[] | undefined>();
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<HTMLElement | null>(
    null
  );

  const isMobileMenuOpen = Boolean(moreAnchorEl);

  notifier.titleChanged = (value) => setTitle(value);
  notifier.menuItemsChanged = (items) => setMenuItems(items);

  const handleMoreMenuClose = () => {
    setMoreAnchorEl(null);
  };

  const handleLogout = () => {
    handleMoreMenuClose();

    Auth.signOut()
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMoreMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const moreMenuId = "more-menu";
  const renderMoreMenu = (
    <Menu
      getContentAnchorEl={null}
      anchorEl={moreAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id={moreMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMoreMenuClose}>
      {!menuItems
        ? null
        : menuItems.map((item, index) => {
            return (
              <MenuItem
                key={item.id}
                onClick={(event) => {
                  handleMoreMenuClose();
                  item.onInvoke(event);
                }}>
                <ListItemIcon aria-label={item.help}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </MenuItem>
            );
          })}
      {!menuItems ? null : <Divider component='li' />}
      <MenuItem key='logout' onClick={handleLogout}>
        <ListItemIcon aria-label='account of current user'>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary='Logout' />
      </MenuItem>
    </Menu>
  );

  return (
    <Box className={classes.grow}>
      <Toolbar className={classes.toolbar}>
        <IconButton color='inherit' aria-label='open drawer'>
          <MenuIcon />
        </IconButton>

        <Typography className={classes.title} variant='h6' noWrap>
          {title}
        </Typography>

        <IconButton
          className={classes.moreButton}
          aria-label='show more'
          aria-controls={moreMenuId}
          aria-haspopup='true'
          onClick={handleMoreMenuOpen}
          color='inherit'>
          <MoreIcon />
        </IconButton>
      </Toolbar>
      {renderMoreMenu}
    </Box>
  );
};

export default HeaderToolbar;
