import gql from "graphql-tag";
import * as Yup from "yup";
//import { pageInfoFragment } from "../CommonHelpers";
import { memberOrgListFragment } from "../User/UserHelpers";

export interface OrgPageProps {
  orgId: string;
}

const orgFragment = gql`
  fragment OrgData on Org {
    id
    title
    desc
    owner
  }
`;

export const queryGetOrg = gql`
  query getOrg($id: ID!) {
    getOrg(id: $id) {
      ...OrgData
    }
  }
  ${orgFragment}
`;

export const mutationAddOrg = gql`
  mutation addOrg($org: OrgInput!) {
    addOrg(org: $org) {
      ...OrgData
    }
  }
  ${orgFragment}
`;

export const mutationEditOrg = gql`
  mutation editOrg($id: ID!, $org: OrgInput!) {
    editOrg(id: $id, org: $org) {
      ...OrgData
    }
  }
  ${orgFragment}
`;

export const validationSchemaOrgInput = Yup.object().shape({
  title: Yup.string().max(256, "Too Long!").required("Required"),
  desc: Yup.string(),
});

export const queryGetOrgInfo = gql`
  query getOrgInfo($id: ID!) {
    getOrg(id: $id) {
      ...OrgData
    }
    getOrgInfo(id: $id) {
      id
      members {
        totalCount
        addCount
        addOn
        delCount
        delOn
      }
      groups {
        totalCount
        addCount
        addOn
        delCount
        delOn
      }
      topics {
        totalCount
        addCount
        addOn
        delCount
        delOn
      }
      events {
        totalCount
        addCount
        addOn
        delCount
        delOn
      }
      signups {
        totalCount
        addCount
        addOn
        delCount
        delOn
      }
    }
  }
  ${orgFragment}
`;

export const queryListMemberOrgsForUser = gql`
  query listMemberOrgsForUser($range: PaginationInput) {
    listMemberOrgsForUser(range: $range) @connection(key: "memberOrg") {
      ...MemberOrgListData
    }
  }
  ${memberOrgListFragment}
`;
