import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";

import UserAdd from "./UserAdd";
import UserGet from "./UserGet";
import UserEdit from "./UserEdit";

import { useAppBarModel } from "../../utils/appBarContext";
import { queryGetUser } from "./UserHelpers";
import { getUser } from "./gentypes/getUser";

const User: React.FC = () => {
  const { path } = useRouteMatch();
  const appBarModel = useAppBarModel();

  const { data } = useQuery<getUser>(queryGetUser);

  React.useEffect(() => {
    // Call setTitle within useEffect to avoid updates during render.
    // See "Warning: Cannot update a component from inside the function body of a different component."
    // in https://reactjs.org/blog/2020/02/26/react-v16.13.0.html
    const fullname = data?.getUser?.fullname;
    if (fullname) {
      appBarModel.setTitle(`Welcome ${fullname}`);
    }
  }, [appBarModel, data]);

  return (
    <Switch>
      <Route path={`${path}/get`} component={UserGet} />
      <Route path={`${path}/add`} component={UserAdd} />
      <Route path={`${path}/edit`} component={UserEdit} />
      <Route exact path={`${path}/`} component={UserGet} />
    </Switch>
  );
};

export default User;
