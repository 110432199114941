import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";

import PageContainer, { PageContainerProps } from "./PageContainer";

const useStyles = makeStyles(theme => ({
  loadMoreBox: {
    position: "relative"
  },
  loadMoreButton: {
    margin: theme.spacing(3, 1)
  },
  loadMoreProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -15,
    marginLeft: -15
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  }
}));

export interface PageListContainerProps extends PageContainerProps {
  hasNextPage: boolean;
  onLoadMore(event: React.MouseEvent<HTMLElement>): void;
}

const PageListContainer: React.FC<PageListContainerProps> = ({
  hasData,
  loading,
  hasNextPage,
  onLoadMore,
  children,
  ...containerProps
}) => {
  const classes = useStyles();

  return (
    <PageContainer hasData={hasData} loading={loading} {...containerProps}>
      <List>{children}</List>
      <Box className={classes.footer}>
        {hasData && (
          <Box className={classes.loadMoreBox}>
            <Button
              className={classes.loadMoreButton}
              variant="outlined"
              size="large"
              color="primary"
              disabled={!hasNextPage || loading}
              onClick={onLoadMore}
            >
              {hasNextPage ? "Load more..." : "End of list"}
            </Button>
            {loading && (
              <CircularProgress
                size={30}
                className={classes.loadMoreProgress}
              />
            )}
          </Box>
        )}
      </Box>
    </PageContainer>
  );
};

export default PageListContainer;
