import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import SignupList from "./SignupList";
import SignupAdd from "./SignupAdd";
import SignupGet from "./SignupGet";
import SignupEdit from "./SignupEdit";
import Action from "./Action/Action";

const Signup: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/list`} component={SignupList} />
      <Route path={`${path}/add`} component={SignupAdd} />
      <Route path={`${path}/:signupId/get`} component={SignupGet} />
      <Route path={`${path}/:signupId/edit`} component={SignupEdit} />
      <Route path={`${path}/:signupId/action`} component={Action} />
      <Route exact path={`${path}/:signupId/`} component={SignupGet} />
      <Route exact path={`${path}/`} component={SignupList} />
    </Switch>
  );
};

export default Signup;
