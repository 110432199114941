import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PersonIcon from "@material-ui/icons/Person";
import RefreshIcon from "@material-ui/icons/Refresh";

import PageListContainer from "../../../components/PageListContainer";
import { mergePageInfo } from "../../CommonHelpers";
import { queryListReplies } from "./ReplyHelpers";
import { listReplies, listRepliesVariables } from "./gentypes/listReplies";
import { TopicPageProps } from "../TopicHelpers";

const pageSize = 5;

const ReplyList: React.FC = () => {
  const history = useHistory();
  const { topicId } = useParams<TopicPageProps>();

  const { loading, error, data, fetchMore, refetch } = useQuery<
    listReplies,
    listRepliesVariables
  >(queryListReplies, {
    variables: {
      topicId: topicId!,
      range: { first: pageSize },
    },
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
  });

  const onLoadMore = React.useCallback(() => {
    const pageInfo = data?.listReplies?.pageInfo;
    if (!pageInfo || !pageInfo.hasNextPage || !pageInfo.endCursor) return data;

    return fetchMore({
      variables: {
        range: {
          first: pageSize,
          after: pageInfo.endCursor,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        const prevData = previousResult.listReplies;
        const nextData = fetchMoreResult.listReplies;
        const edges = nextData?.edges?.length
          ? [...prevData!.edges!, ...nextData.edges]
          : nextData.edges;
        return {
          // Put the new comments at the end of the list and update `pageInfo`
          // so we have the new `endCursor` and `hasNextPage` values
          listReplies: {
            __typename: prevData.__typename,
            edges: edges,
            pageInfo: mergePageInfo(prevData.pageInfo, nextData.pageInfo),
          },
        };
      },
    });
  }, [data, fetchMore]);

  const onRefresh = React.useCallback(() => {
    if (!loading) {
      refetch();
    }
  }, [refetch, loading]);

  const menuItems = React.useMemo(() => {
    return [
      {
        id: "add",
        name: "Add Reply",
        help: "Add reply to organization",
        icon: <AddIcon />,
        onInvoke: () => history.push(`add`),
        fab: true,
      },
      {
        id: "reload",
        name: "Reload Topics",
        help: "Reload topic list",
        icon: <RefreshIcon />,
        onInvoke: () => onRefresh(),
      },
    ];
  }, [onRefresh, history]);

  const listData = data?.listReplies?.edges;
  const hasNextPage = data?.listReplies?.pageInfo?.hasNextPage === true;

  return (
    <PageListContainer
      loading={loading}
      error={error}
      hasData={data !== undefined}
      hasNextPage={hasNextPage}
      onLoadMore={onLoadMore}
      menuItems={menuItems}>
      {!listData
        ? false
        : listData.map(({ node }, index) => {
            return (
              <ListItem
                key={node.id}
                button
                divider={index !== listData.length - 1}
                onClick={() => history.push(`${node.id}/get`)}>
                <ListItemAvatar>
                  <Avatar>
                    <Badge color='secondary'>
                      <PersonIcon fontSize='large' />
                    </Badge>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={node.body} />
                <ArrowForwardIosIcon />
              </ListItem>
            );
          })}
    </PageListContainer>
  );
};

export default ReplyList;
