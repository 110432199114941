import React from "react";
import { Route, Switch } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Home from "../pages/User/Home";
import User from "../pages/User/User";
import Org from "../pages/Org/Org";
import OrgAdd from "../pages/Org/OrgAdd";

import FadeTransitionRouter from "./FadeTransitionRouter";
import HeaderToolbar, { HeaderNotifier } from "./HeaderToolbar";
import HideOnScroll from "./HideOnScroll";
import { AppBarContext, AppMenuItem } from "../utils/appBarContext";

const Root: React.FC = ({ children }) => {
  const appBarModelNotify: HeaderNotifier = {
    titleChanged: () => {},
    menuItemsChanged: () => {},
  };

  const appBarModel = {
    setTitle(title: string) {
      appBarModelNotify.titleChanged(title);
    },
    addMenuItems(items: AppMenuItem[]) {
      appBarModelNotify.menuItemsChanged(items);
    },
  };

  return (
    <AppBarContext.Provider value={appBarModel}>
      <HideOnScroll>
        <AppBar>
          <HeaderToolbar notifier={appBarModelNotify} />
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      {children}
    </AppBarContext.Provider>
  );
};

const App: React.FC = () => {
  return (
    <Root>
      <FadeTransitionRouter>
        <Switch>
          <Route path='/user' component={User} />
          <Route path='/org/add' component={OrgAdd} />
          <Route path='/org/:orgId' component={Org} />
          <Route exact path='/' component={Home} />
        </Switch>
      </FadeTransitionRouter>
    </Root>
  );
};

export default App;
