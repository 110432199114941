import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import TopicList from "./TopicList";
import TopicAdd from "./TopicAdd";
import TopicGet from "./TopicGet";
import TopicEdit from "./TopicEdit";
import Reply from "./Reply/Reply";

const Topic: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/list`} component={TopicList} />
      <Route path={`${path}/add`} component={TopicAdd} />
      <Route path={`${path}/:topicId/get`} component={TopicGet} />
      <Route path={`${path}/:topicId/edit`} component={TopicEdit} />
      <Route path={`${path}/:topicId/reply`} component={Reply} />
      <Route exact path={`${path}/:topicId/`} component={TopicGet} />
      <Route exact path={`${path}/`} component={TopicList} />
    </Switch>
  );
};

export default Topic;
