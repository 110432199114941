import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Form } from "formik";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container, { ContainerProps } from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  forum: {
    // NOTE: Use padding of 2 on left/right (vs 1) to allow selection menu to be
    // positioned correctly and not get shifted over (probably because of paper shadowning)
    padding: theme.spacing(0, 2, 0, 2),
    "& > *": {
      margin: theme.spacing(1, 0, 1, 0)
    }
  },
  toolbar: {
    "& > *": {
      margin: theme.spacing(1)
    }
  }
}));

export interface FormContainerProps extends ContainerProps {
  isSubmitting: boolean;
  onCancel:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  submitForm: (() => Promise<void>) & (() => Promise<any>);
}

const FormContainer: React.FC<FormContainerProps> = props => {
  const classes = useStyles();
  const {
    isSubmitting,
    onCancel,
    submitForm,
    children,
    maxWidth = "sm",
    disableGutters = true,
    ...containerProps
  } = props;
  return (
    <Container
      maxWidth={maxWidth}
      disableGutters={disableGutters}
      {...containerProps}
    >
      <Form className={classes.forum}>
        {children}
        {isSubmitting && <LinearProgress />}
        <Box className={classes.toolbar}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={onCancel}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={submitForm}
            startIcon={<DoneIcon />}
          >
            Submit
          </Button>
        </Box>
      </Form>
    </Container>
  );
};

export default FormContainer;
