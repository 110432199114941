import { Auth } from "aws-amplify";
import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import {
  AuthOptions,
  createAuthLink,
  Signer,
  AUTH_TYPE,
} from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

const isLocalhost = process.env.REACT_APP_LOCALHOST === "true";

const amplifyAuth = isLocalhost
  ? {}
  : {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_REGION,
      identityPoolRegion: process.env.REACT_APP_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      // mandatorySignIn: true,
      mandatorySignIn: true,
      /*
      // OPTIONAL - Configuration for cookie storage
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: process.env.REACT_APP_API_DOMAIN,
        // OPTIONAL - Cookie path
        path: "/",
        // OPTIONAL - Cookie expiration in days
        expires: 365,
        // OPTIONAL - Cookie secure flag
        secure: true
      }
      */
    };

const appSyncConfigAuth: AuthOptions = isLocalhost
  ? { type: AUTH_TYPE.NONE }
  : {
      type: AUTH_TYPE.AWS_IAM,
      credentials: () => Auth.currentCredentials(),
    };

const amplifyConfig = {
  Auth: amplifyAuth,
  API: {
    ///graphql_endpoint: "https://api-dev.teamilize.com/graphql2",
    graphql_endpoint: process.env.REACT_APP_API_GRAPHQL_URL, //"http://localhost:4000/graphql",
    //    aws_appsync_gdraphqlEndpoint:
    //      'https://gzmh3rkygg.execute-api.us-west-2.amazonaws.com/dev/graphql',
    //    aws_appsync_region: 'us-west-2',
    //graphql_endpoint_iam_region: "us-west-2"
    graphql_endpoint_iam_region: process.env.REACT_APP_REGION,
    //    aws_appsync_authenticationType: 'AWS_IAM',
  },
};

if (!isLocalhost) {
  // May not need to be in isLocalhost
  const oldSign = Signer.sign;
  const newSign = (request: any, access_info: any, service_info?: any) => {
    return oldSign(request, access_info, {
      service: "execute-api",
      region: process.env.REACT_APP_REGION,
    });
  };
  Signer.sign = newSign;
}

const federatedConfig = {
  //amazonClientId: "amzn1.application-oa2-client.12f1923c06814c9ab05c32e87b6cc5a4",
  facebookAppId: "554962128207370",
  googleClientId:
    "376145565389-mo50nmabgjmog47ellir0c03gu918es1.apps.googleusercontent.com",
};

const authLinkConfig = {
  url: amplifyConfig.API.graphql_endpoint!,
  region: amplifyConfig.API.graphql_endpoint_iam_region!,
  auth: appSyncConfigAuth,
};

const link = ApolloLink.from([
  createAuthLink(authLinkConfig),
  createSubscriptionHandshakeLink(authLinkConfig),
]);

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const signUpConfig = {
  usernameAttributes: "email",
  hiddenDefaults: ["username", "phone_number", "email"],
  signUpFields: [
    {
      label: "Email",
      key: "username",
      required: true,
      displayOrder: 1,
      type: "string",
    },
    {
      label: "Password",
      key: "password",
      required: true,
      displayOrder: 2,
      type: "password",
    },
  ],
};

export {
  amplifyConfig,
  apolloClient,
  federatedConfig,
  isLocalhost,
  signUpConfig,
};
