import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import GroupList from "./GroupList";
import GroupAdd from "./GroupAdd";
import GroupRoutes from "./GroupRoutes";

// Do we need to show org name also
// import { queryGetOrgInfo } from "./OrgHelpers";
// import { getOrgInfo, getOrgInfoVariables } from "./gentypes/getOrgInfo";

const Group: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/list`} component={GroupList} />
      <Route path={`${path}/add`} component={GroupAdd} />
      <Route path={`${path}/:groupId`} component={GroupRoutes} />
      <Route exact path={`${path}/`} component={GroupList} />
    </Switch>
  );
};

export default Group;
