import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import TopicForm, { TopicFormData } from "./TopicForm";
import {
  queryListTopics,
  mutationAddTopic,
  validationSchemaTopicInput,
} from "./TopicHelpers";
import { listTopics, listTopics_listTopics_edges } from "./gentypes/listTopics";
import { addTopic, addTopicVariables } from "./gentypes/addTopic";
import { OwnerPageProps } from "../CommonHelpers";

const defaultTopicInput: TopicFormData = {
  title: "",
  body: "",
};

const TopicAdd: React.FC = () => {
  const history = useHistory();
  const { orgId, groupId } = useParams<OwnerPageProps>();
  const ownerId = groupId || orgId;

  const [doMutation] = useMutation<addTopic, addTopicVariables>(
    mutationAddTopic
  );

  const onSubmit = React.useCallback(
    (values: TopicFormData, actions: FormikHelpers<TopicFormData>) => {
      doMutation({
        variables: {
          ownerId: ownerId!,
          topic: {
            title: values.title,
            body: values.body,
          },
        },
        update: (cache, { data }) => {
          const readData = cache.readQuery<listTopics>({
            query: queryListTopics,
            variables: {
              ownerId: ownerId!,
            },
          });

          // Only add to query if data is present readQuery may throw
          const queryData = readData!.listTopics;
          if (queryData) {
            const edges: listTopics_listTopics_edges[] = [
              {
                __typename: "TopicEdge",
                cursor: "",
                node: data!.addTopic!,
              },
              ...queryData!.edges!,
            ];

            const writeData: listTopics = {
              listTopics: {
                __typename: queryData.__typename,
                edges,
                pageInfo: { ...queryData.pageInfo },
              },
            };
            cache.writeQuery({
              query: queryListTopics,
              variables: {
                ownerId: ownerId!,
              },
              data: writeData,
            });

            // TODO: Update owner's topic total and added data, or invalidate orgInfo cache
          }
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, ownerId, history]
  );

  const onCancel = () => {
    history.goBack();
  };

  return (
    <Formik
      initialValues={defaultTopicInput}
      onSubmit={onSubmit}
      validationSchema={validationSchemaTopicInput}>
      {({ submitForm, isSubmitting }) => {
        return (
          <TopicForm
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            onCancel={onCancel}>
            {}
          </TopicForm>
        );
      }}
    </Formik>
  );
};

export default TopicAdd;
