import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import LoadingContainer from "../../components/LoadingContainer";
import OrgForm, { OrgFormData } from "./OrgForm";
import {
  queryGetOrg,
  mutationEditOrg,
  validationSchemaOrgInput,
  OrgPageProps,
} from "./OrgHelpers";
import { getOrg, getOrgVariables } from "./gentypes/getOrg";
import { editOrg, editOrgVariables } from "./gentypes/editOrg";

const OrgEdit: React.FC = () => {
  const history = useHistory();
  const { orgId } = useParams<OrgPageProps>();

  const { loading, error, data } = useQuery<getOrg, getOrgVariables>(
    queryGetOrg,
    {
      variables: {
        id: orgId!,
      },
      partialRefetch: true,
    }
  );

  const initialValues = React.useMemo<OrgFormData | null>(() => {
    const org = data?.getOrg;
    if (!org) return null;
    return { title: org.title, desc: org.desc };
  }, [data]);

  const [doMutation] = useMutation<editOrg, editOrgVariables>(mutationEditOrg);

  const onSubmit = React.useCallback(
    (values: OrgFormData, actions: FormikHelpers<OrgFormData>) => {
      doMutation({
        variables: {
          id: orgId!,
          org: {
            title: values.title,
            desc: values.desc,
          },
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, orgId, history]
  );

  const onCancel = () => {
    history.goBack();
  };

  return (
    <LoadingContainer loading={loading} error={error}>
      {!initialValues ? (
        {}
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaOrgInput}>
          {({ submitForm, isSubmitting }) => {
            return (
              <OrgForm
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                onCancel={onCancel}>
                {}
              </OrgForm>
            );
          }}
        </Formik>
      )}
    </LoadingContainer>
  );
};

export default OrgEdit;
