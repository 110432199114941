import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from "@material-ui/icons/Person";
import ReplyIcon from "@material-ui/icons/Reply";

import PageContainer from "../../components/PageContainer";
import { EventPageProps, queryGetEvent } from "./EventHelpers";
import { getEvent, getEventVariables } from "./gentypes/getEvent";

const useStyles = makeStyles((theme) => ({
  view: {
    width: "100%",
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(2),
  },
}));

const EventGet: React.FC = () => {
  const history = useHistory();
  const { eventId } = useParams<EventPageProps>();
  const classes = useStyles();

  const { loading, error, data } = useQuery<getEvent, getEventVariables>(
    queryGetEvent,
    {
      variables: {
        id: eventId!,
      },
    }
  );

  const menuItems = React.useMemo(() => {
    return [
      {
        id: "reply",
        name: "Reply",
        help: "Reply to event",
        icon: <ReplyIcon />,
        onInvoke: () => history.push(`reply/list`),
        fab: true,
      },
      {
        id: "edit",
        name: "Edit Event",
        help: "Edit event for organization",
        icon: <EditIcon />,
        onInvoke: () => history.push(`edit`),
      },
      {
        id: "delete",
        name: "Delete Event",
        help: "Delete event for organization",
        icon: <DeleteIcon />,
        onInvoke: () => history.push(`edit`),
      },
    ];
  }, [history]);

  const event = data?.getEvent;

  return (
    <PageContainer loading={loading} error={error} menuItems={menuItems}>
      {!event ? (
        false
      ) : (
        <List className={classes.view}>
          <ListItem>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon fontSize='large' />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={event.title} secondary={event.body} />
          </ListItem>
          <Divider component='li' />
        </List>
      )}
    </PageContainer>
  );
};

export default EventGet;
