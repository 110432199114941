import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import LoadingContainer from "../../../components/LoadingContainer";
import ReplyForm, { ReplyFormData } from "./ReplyForm";
import {
  queryGetReply,
  mutationEditReply,
  validationSchemaReplyInput,
  ReplyPageProps,
} from "./ReplyHelpers";
import { getReply, getReplyVariables } from "./gentypes/getReply";
import { editReply, editReplyVariables } from "./gentypes/editReply";

const ReplyEdit: React.FC = () => {
  const history = useHistory();
  const { replyId } = useParams<ReplyPageProps>();
  const { loading, error, data } = useQuery<getReply, getReplyVariables>(
    queryGetReply,
    {
      variables: {
        id: replyId!,
      },
    }
  );

  const initialValues = React.useMemo<ReplyFormData | null>(() => {
    const reply = data?.getReply;
    if (!reply) return null;
    return {
      body: reply.body,
    };
  }, [data]);

  const [doMutation] = useMutation<editReply, editReplyVariables>(
    mutationEditReply
  );

  const onSubmit = React.useCallback(
    (values: ReplyFormData, actions: FormikHelpers<ReplyFormData>) => {
      doMutation({
        variables: {
          id: replyId!,
          reply: {
            body: values.body,
          },
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, replyId, history]
  );

  const onCancel = () => {
    history.push(`get`);
  };

  return (
    <LoadingContainer loading={loading} error={error}>
      {!initialValues ? (
        {}
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaReplyInput}>
          {({ submitForm, isSubmitting }) => {
            return (
              <ReplyForm
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                onCancel={onCancel}>
                {}
              </ReplyForm>
            );
          }}
        </Formik>
      )}
    </LoadingContainer>
  );
};

export default ReplyEdit;
