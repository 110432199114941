import gql from "graphql-tag";
import * as Yup from "yup";
import { pageInfoFragment } from "../CommonHelpers";
import { OrgPageProps } from "../Org/OrgHelpers";

export interface MemberPageProps extends OrgPageProps {
  memberId: string;
}

export const memberFragment = gql`
  fragment MemberData on Member {
    id
    userId
    name
    info
    photoUrl
    type
    role
    email
    groups {
      groupId
      role
    }
  }
`;

export const memberListFragment = gql`
  fragment MemberListData on MemberConnection {
    edges {
      cursor
      node {
        ...MemberData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
  ${pageInfoFragment}
  ${memberFragment}
`;

export const queryListMembers = gql`
  query listMembers($orgId: ID!) {
    listMembers(orgId: $orgId) @connection(key: "members", filter: ["orgId"]) {
      ...MemberListData
    }
  }
  ${memberListFragment}
`;

export const queryGetMember = gql`
  query getMember($id: ID!) {
    getMember(id: $id) {
      ...MemberData
    }
  }
  ${memberFragment}
`;

export const mutationAddMember = gql`
  mutation addMember($orgId: ID!, $member: MemberInput!) {
    addMember(orgId: $orgId, member: $member) {
      ...MemberData
    }
  }
  ${memberFragment}
`;

export const mutationEditMember = gql`
  mutation editMember($id: ID!, $member: MemberInput!) {
    editMember(id: $id, member: $member) {
      ...MemberData
    }
  }
  ${memberFragment}
`;

//listMembersForUser(range: PaginationInput): MemberConnection!
export const queryListMembersForUser = gql`
  query listMembersForUser($range: PaginationInput) {
    listMembersForUser(range: $range) @connection(key: "members") {
      ...MemberListData
    }
  }
  ${memberListFragment}
`;

export const validationSchemaMemberInput = Yup.object().shape({
  name: Yup.string().max(256, "Too Long!").required("Required"),
  info: Yup.string(),
});

export const memberRole = [
  { type: "BANNED", name: "Banned" },
  { type: "GUEST", name: "Guest" },
  { type: "MEMBER", name: "Member" },
  { type: "PARTICIPANT", name: "Participant" },
  { type: "LEADER", name: "Leader" },
  { type: "ADMIN", name: "Admin" },
  { type: "OWNER", name: "Owner" },
];

export const memberType = [
  { type: "ADULT", name: "Adult" },
  { type: "UNDER18", name: "Under 18" },
  { type: "UNDER13", name: "Under 13" },
];
