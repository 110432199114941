import React from "react";
import { Field } from "formik";
import { TextField, Switch } from "formik-material-ui";
import { KeyboardDateTimePicker } from "formik-material-ui-pickers";

import FormContainer, {
  FormContainerProps,
} from "../../components/FormContainer";

export type EventFormData = {
  title: string;
  start: number;
  end: number;
  allDay: boolean | null;
  location: string | null;
  body: string | null;
};

const EventForm: React.FC<FormContainerProps> = (props) => {
  return (
    <FormContainer {...props}>
      <Field
        component={TextField}
        fullWidth
        variant='outlined'
        name='title'
        type='text'
        label='Title'
      />
      <Field
        component={KeyboardDateTimePicker}
        fullWidth
        variant='outlined'
        name='start'
        label='Start'
      />
      <Field
        component={KeyboardDateTimePicker}
        fullWidth
        variant='outlined'
        name='end'
        label='End'
      />
      <Field
        component={Switch}
        fullWidth
        variant='outlined'
        name='allDay'
        type='checkbox'
        label='All day'
      />
      <Field
        component={TextField}
        fullWidth
        variant='outlined'
        name='location'
        type='text'
        label='Location'
      />
      <Field
        component={TextField}
        fullWidth
        variant='outlined'
        multiline
        rows={4}
        rowsMax={4}
        name='body'
        label='Body'
      />
    </FormContainer>
  );
};

export default EventForm;
