import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";

import FormContainer, {
  FormContainerProps,
} from "../../components/FormContainer";

export type GroupFormData = {
  title: string;
  desc: string | null;
};

const GroupForm: React.FC<FormContainerProps> = (props) => {
  return (
    <FormContainer {...props}>
      <Field
        component={TextField}
        fullWidth
        variant='outlined'
        name='title'
        type='text'
        label='Title'
      />
      <Field
        component={TextField}
        fullWidth
        variant='outlined'
        multiline
        rows={4}
        rowsMax={4}
        name='desc'
        label='Description'
      />
    </FormContainer>
  );
};

export default GroupForm;
