import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from "@material-ui/icons/Person";
import ReplyIcon from "@material-ui/icons/Reply";

import PageContainer from "../../components/PageContainer";
import { queryGetTopic, TopicPageProps } from "./TopicHelpers";
import { getTopic, getTopicVariables } from "./gentypes/getTopic";

const useStyles = makeStyles((theme) => ({
  view: {
    width: "100%",
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(2),
  },
}));

const TopicGet: React.FC = () => {
  const history = useHistory();
  const { topicId } = useParams<TopicPageProps>();
  const classes = useStyles();

  const { loading, error, data } = useQuery<getTopic, getTopicVariables>(
    queryGetTopic,
    {
      variables: {
        id: topicId!,
      },
    }
  );

  const menuItems = React.useMemo(() => {
    return [
      {
        id: "reply",
        name: "Reply",
        help: "Reply to topic",
        icon: <ReplyIcon />,
        onInvoke: () => history.push(`reply/add`),
        fab: true,
      },
      {
        id: "edit",
        name: "Edit Topic",
        help: "Edit topic for organization",
        icon: <EditIcon />,
        onInvoke: () => history.push(`edit`),
      },
      {
        id: "delete",
        name: "Delete Topic",
        help: "Delete topic for organization",
        icon: <DeleteIcon />,
        onInvoke: () => history.push(`edit`),
      },
    ];
  }, [history]);

  const topic = data?.getTopic;

  return (
    <PageContainer loading={loading} error={error} menuItems={menuItems}>
      {!topic ? (
        false
      ) : (
        <List className={classes.view}>
          <ListItem onClick={() => history.push(`reply/list`)}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon fontSize='large' />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={topic.title} secondary={topic.body} />
          </ListItem>
          <Divider component='li' />
        </List>
      )}
    </PageContainer>
  );
};

export default TopicGet;
