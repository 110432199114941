import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";

import {
  mutationEditUser,
  queryGetUser,
  validationSchemaUserInput,
} from "./UserHelpers";
import { editUser, editUserVariables } from "./gentypes/editUser";
import { getUser } from "./gentypes/getUser";
import UserForm, { UserFormData } from "./UserForm";
import LoadingContainer from "../../components/LoadingContainer";

const UserEdit: React.FC = () => {
  const history = useHistory();
  const { loading, error, data } = useQuery<getUser>(queryGetUser);

  const initialValues = React.useMemo<UserFormData | null>(() => {
    const user = data?.getUser;
    if (!user) return null;
    return { fullname: user.fullname, nickname: user.nickname };
  }, [data]);

  const [doMutation] = useMutation<editUser, editUserVariables>(
    mutationEditUser
  );

  const onSubmit = React.useCallback(
    (values: UserFormData, actions: FormikHelpers<UserFormData>) => {
      doMutation({
        variables: {
          user: {
            fullname: values.fullname,
            nickname: values.nickname,
          },
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, history]
  );

  const onCancel = () => {
    history.push(`get`);
  };

  return (
    <LoadingContainer loading={loading} error={error}>
      {!initialValues ? (
        {}
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaUserInput}>
          {({ submitForm, isSubmitting }) => {
            return (
              <UserForm
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                onCancel={onCancel}>
                {}
              </UserForm>
            );
          }}
        </Formik>
      )}
    </LoadingContainer>
  );
};

export default UserEdit;
