import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";

import FormContainer, {
  FormContainerProps,
} from "../../components/FormContainer";
import { SignupType } from "./../../globalTypes";

/*
Signup = need response or have limited number
Event Response = optional

Simple scenarios:
- Limited number of slots
- Desired slots

Types:
- event slots (go), job slots (do), item slots (bring), 

Slots:
- choice, time, plan text, 

export enum SignupType {
  EVENT = "EVENT",
  NEED = "NEED",
  RECURRING = "RECURRING",
  TIMESLOTS = "TIMESLOTS"
}
*/

export type SignupFormData = {
  title: string;
  type: SignupType;
  details: string | null;
  location: string | null;
};

const SignupForm: React.FC<FormContainerProps> = (props) => {
  return (
    <FormContainer {...props}>
      <Field
        component={TextField}
        fullWidth
        variant='outlined'
        name='title'
        type='text'
        label='Title'
      />

      <Field
        component={TextField}
        fullWidth
        variant='outlined'
        name='location'
        type='text'
        label='Location'
      />
      <Field
        component={TextField}
        fullWidth
        variant='outlined'
        multiline
        rows={4}
        rowsMax={4}
        name='body'
        label='Body'
      />
    </FormContainer>
  );
};

export default SignupForm;
