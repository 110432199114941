import gql from "graphql-tag";
import * as Yup from "yup";
import { OwnerPageProps, pageInfoFragment } from "../CommonHelpers";

export interface TopicPageProps extends OwnerPageProps {
  topicId: string;
}

const topicFragment = gql`
  fragment TopicData on Topic {
    id
    from
    title
    body
    sent
    repliesAdded
    repliesAddedOn
    repliesDeleted
    repliesDeletedOn
    repliesTotal
  }
`;

const topicListFragment = gql`
  fragment TopicListData on TopicConnection {
    edges {
      cursor
      node {
        ...TopicData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
  ${pageInfoFragment}
  ${topicFragment}
`;

export const queryListTopics = gql`
  query listTopics($ownerId: ID!, $range: PaginationInput) {
    listTopics(ownerId: $ownerId, range: $range)
      @connection(key: "topics", filter: ["ownerId"]) {
      ...TopicListData
    }
  }
  ${topicListFragment}
`;

export const queryGetTopic = gql`
  query getTopic($id: ID!) {
    getTopic(id: $id) {
      ...TopicData
    }
  }
  ${topicFragment}
`;

export const mutationAddTopic = gql`
  mutation addTopic($ownerId: ID!, $topic: TopicInput!) {
    addTopic(ownerId: $ownerId, topic: $topic) {
      ...TopicData
    }
  }
  ${topicFragment}
`;

export const mutationEditTopic = gql`
  mutation editTopic($id: ID!, $topic: TopicInput!) {
    editTopic(id: $id, topic: $topic) {
      ...TopicData
    }
  }
  ${topicFragment}
`;

export const validationSchemaTopicInput = Yup.object().shape({
  title: Yup.string().max(256).required(),
  body: Yup.string().max(1024),
});
