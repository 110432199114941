import gql from "graphql-tag";

export const pageInfoFragment = gql`
  fragment PageInfoData on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;

export interface PageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

export function mergePageInfo(prevPage: PageInfo, nextPage: PageInfo) {
  return {
    __typename: prevPage.__typename,
    endCursor: nextPage.endCursor,
    hasNextPage: nextPage.hasNextPage,
    startCursor: prevPage.startCursor,
    hasPreviousPage: prevPage.hasPreviousPage,
  };
}

export interface OrgPageProps {
  orgId: string;
}

export interface OwnerPageProps extends OrgPageProps {
  groupId?: string;
}
