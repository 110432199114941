import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import LoadingContainer from "../../components/LoadingContainer";
import TopicForm, { TopicFormData } from "./TopicForm";
import {
  queryGetTopic,
  mutationEditTopic,
  validationSchemaTopicInput,
  TopicPageProps,
} from "./TopicHelpers";
import { getTopic, getTopicVariables } from "./gentypes/getTopic";
import { editTopic, editTopicVariables } from "./gentypes/editTopic";

const TopicEdit: React.FC = () => {
  const history = useHistory();
  const { topicId } = useParams<TopicPageProps>();

  const { loading, error, data } = useQuery<getTopic, getTopicVariables>(
    queryGetTopic,
    {
      variables: {
        id: topicId!,
      },
    }
  );

  const initialValues = React.useMemo<TopicFormData | null>(() => {
    const topic = data?.getTopic;
    if (!topic) return null;
    return {
      title: topic.title,
      body: topic.body || "",
    };
  }, [data]);

  const [doMutation] = useMutation<editTopic, editTopicVariables>(
    mutationEditTopic
  );

  const onSubmit = React.useCallback(
    (values: TopicFormData, actions: FormikHelpers<TopicFormData>) => {
      doMutation({
        variables: {
          id: topicId!,
          topic: {
            title: values.title,
            body: values.body,
          },
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, topicId, history]
  );

  const onCancel = () => {
    history.push(`get`);
  };

  return (
    <LoadingContainer loading={loading} error={error}>
      {!initialValues ? (
        false
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaTopicInput}>
          {({ submitForm, isSubmitting }) => {
            return (
              <TopicForm
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                onCancel={onCancel}>
                {}
              </TopicForm>
            );
          }}
        </Formik>
      )}
    </LoadingContainer>
  );
};

export default TopicEdit;
