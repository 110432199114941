import gql from "graphql-tag";
import * as Yup from "yup";
import { pageInfoFragment } from "../../CommonHelpers";
import { TopicPageProps } from "../TopicHelpers";

export interface ReplyPageProps extends TopicPageProps {
  replyId: string;
}

const replyFragment = gql`
  fragment ReplyData on Reply {
    id
    from
    body
    sent
    editOn
    editBy
  }
`;

const replyListFragment = gql`
  fragment ReplyListData on ReplyConnection {
    edges {
      cursor
      node {
        ...ReplyData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
  ${pageInfoFragment}
  ${replyFragment}
`;

export const queryListReplies = gql`
  query listReplies($topicId: ID!, $range: PaginationInput) {
    listReplies(topicId: $topicId, range: $range)
      @connection(key: "replies", filter: ["topicId"]) {
      ...ReplyListData
    }
  }
  ${replyListFragment}
`;

export const queryGetReply = gql`
  query getReply($id: ID!) {
    getReply(id: $id) {
      ...ReplyData
    }
  }
  ${replyFragment}
`;

export const mutationAddReply = gql`
  mutation addReply($topicId: ID!, $reply: ReplyInput!) {
    addReply(topicId: $topicId, reply: $reply) {
      ...ReplyData
    }
  }
  ${replyFragment}
`;

export const mutationEditReply = gql`
  mutation editReply($id: ID!, $reply: ReplyInput!) {
    editReply(id: $id, reply: $reply) {
      ...ReplyData
    }
  }
  ${replyFragment}
`;

export const validationSchemaReplyInput = Yup.object().shape({
  body: Yup.string().max(1024).required(),
});
