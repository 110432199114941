import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import LoadingContainer from "../../components/LoadingContainer";
import EventForm, { EventFormData } from "./EventForm";
import {
  queryGetEvent,
  mutationEditEvent,
  validationSchemaEventInput,
  EventPageProps,
} from "./EventHelpers";
import { getEvent, getEventVariables } from "./gentypes/getEvent";
import { editEvent, editEventVariables } from "./gentypes/editEvent";

const EventEdit: React.FC = () => {
  const history = useHistory();
  const { eventId } = useParams<EventPageProps>();

  const { loading, error, data } = useQuery<getEvent, getEventVariables>(
    queryGetEvent,
    {
      variables: {
        id: eventId!,
      },
    }
  );

  const initialValues = React.useMemo<EventFormData | null>(() => {
    const event = data?.getEvent;
    if (!event) return null;
    return {
      title: event.title,
      start: event.start,
      end: event.end,
      allDay: event.allDay || false,
      location: event.location || "",
      body: event.body || "",
    };
  }, [data]);

  const [doMutation] = useMutation<editEvent, editEventVariables>(
    mutationEditEvent
  );

  const onSubmit = React.useCallback(
    (values: EventFormData, actions: FormikHelpers<EventFormData>) => {
      doMutation({
        variables: {
          id: eventId!,
          event: {
            title: values.title,
            start: values.start,
            end: values.end,
            allDay: values.allDay,
            location: values.location,
            body: values.body,
          },
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, eventId, history]
  );

  const onCancel = () => {
    history.push(`get`);
  };

  return (
    <LoadingContainer loading={loading} error={error}>
      {!initialValues ? (
        {}
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaEventInput}>
          {({ submitForm, isSubmitting }) => {
            return (
              <EventForm
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                onCancel={onCancel}>
                {}
              </EventForm>
            );
          }}
        </Formik>
      )}
    </LoadingContainer>
  );
};

export default EventEdit;
