import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import EditIcon from "@material-ui/icons/Edit";
import GroupIcon from "@material-ui/icons/Group";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import EmailIcon from "@material-ui/icons/Email";
import EventIcon from "@material-ui/icons/Event";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import PageContainer from "../../components/PageContainer";
import { OrgPageProps, queryGetOrgInfo } from "./OrgHelpers";
import { getOrgInfo, getOrgInfoVariables } from "./gentypes/getOrgInfo";

const OrgGet: React.FC = () => {
  const history = useHistory();
  const { orgId } = useParams<OrgPageProps>();

  const { loading, error, data } = useQuery<getOrgInfo, getOrgInfoVariables>(
    queryGetOrgInfo,
    {
      variables: {
        id: orgId!,
      },
    }
  );

  const listData = React.useMemo(() => {
    const info = data?.getOrgInfo;
    if (!info) return null;
    return [
      {
        id: "member",
        title: "Members",
        icon: () => <GroupIcon fontSize='large' color='primary' />,
        badge: info.members.totalCount,
      },
      {
        id: "group",
        title: "Groups",
        icon: () => <GroupWorkIcon fontSize='large' color='primary' />,
        badge: info.groups.totalCount,
      },
      {
        id: "topic",
        title: "Messages",
        icon: () => <EmailIcon fontSize='large' color='primary' />,
        badge: info.topics.totalCount,
      },
      {
        id: "event",
        title: "Events",
        icon: () => <EventIcon fontSize='large' color='primary' />,
        badge: info.events.totalCount,
      },
      {
        id: "signup",
        title: "Signups",
        icon: () => <AssignmentIndIcon fontSize='large' color='primary' />,
        badge: info.signups.totalCount,
      },
    ];
  }, [data]);

  const menuItems = React.useMemo(() => {
    return [
      {
        id: "edit",
        name: "Edit",
        help: "Edit organization",
        icon: <EditIcon />,
        onInvoke: () => history.push(`edit`),
      },
    ];
  }, [history]);

  return (
    <PageContainer loading={loading} error={error} menuItems={menuItems}>
      <List>
        {listData?.map((node, index) => {
          return (
            <ListItem
              key={node.id}
              button
              divider={index + 1 !== listData.length}
              onClick={() => history.push(`${node.id}/list`)}>
              <ListItemIcon>
                <Badge badgeContent={node.badge} color='secondary'>
                  {node.icon()}
                </Badge>
              </ListItemIcon>
              <ListItemText primary={node.title} />
              <ArrowForwardIosIcon />
            </ListItem>
          );
        })}
      </List>
    </PageContainer>
  );
};

export default OrgGet;
