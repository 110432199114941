import React from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import PageContainer from "../../components/PageContainer";
import { queryGetUser } from "./UserHelpers";
import { getUser } from "./gentypes/getUser";

const UserGet: React.FC = () => {
  const history = useHistory();
  const { loading, error, data } = useQuery<getUser>(queryGetUser);

  const menuItems = React.useMemo(() => {
    return [
      {
        id: "edit",
        name: "Edit User",
        help: "Edit user",
        icon: <EditIcon />,
        onInvoke: () => history.push(`edit`),
        fab: true,
      },
      {
        id: "delete",
        name: "Delete User",
        help: "Delete user",
        icon: <DeleteIcon />,
        onInvoke: () => history.push(`delete`),
      },
    ];
  }, [history]);

  const user = data?.getUser;

  return (
    <PageContainer loading={loading} error={error} menuItems={menuItems}>
      {!user ? {} : user.fullname}
    </PageContainer>
  );
};

export default UserGet;
