import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import LoadingContainer from "../../components/LoadingContainer";
import MemberForm, { MemberFormData } from "./MemberForm";
import {
  queryGetMember,
  mutationEditMember,
  validationSchemaMemberInput,
  MemberPageProps,
} from "./MemberHelpers";
import { getMember, getMemberVariables } from "./gentypes/getMember";
import { editMember, editMemberVariables } from "./gentypes/editMember";

const MemberEdit: React.FC = () => {
  const history = useHistory();
  const { memberId } = useParams<MemberPageProps>();

  const { loading, error, data } = useQuery<getMember, getMemberVariables>(
    queryGetMember,
    {
      variables: {
        id: memberId!,
      },
    }
  );

  const initialValues = React.useMemo<MemberFormData | null>(() => {
    const member = data?.getMember;
    if (!member) return null;
    return {
      name: member.name,
      info: member.info || "",
      email: member.email || "",
      role: member.role,
      type: member.type,
    };
  }, [data]);

  const [doMutation] = useMutation<editMember, editMemberVariables>(
    mutationEditMember
  );

  const onSubmit = React.useCallback(
    (values: MemberFormData, actions: FormikHelpers<MemberFormData>) => {
      doMutation({
        variables: {
          id: memberId!,
          member: {
            name: values.name,
            info: values.info,
            email: values.email,
            role: values.role,
            type: values.type,
          },
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, memberId, history]
  );

  const onCancel = () => {
    history.push(`get`);
  };

  return (
    <LoadingContainer loading={loading} error={error}>
      {!initialValues ? (
        {}
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaMemberInput}>
          {({ submitForm, isSubmitting }) => {
            return (
              <MemberForm
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                onCancel={onCancel}>
                {}
              </MemberForm>
            );
          }}
        </Formik>
      )}
    </LoadingContainer>
  );
};

export default MemberEdit;
