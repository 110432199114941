import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";

import FormContainer, {
  FormContainerProps
} from "../../../components/FormContainer";

export type ReplyFormData = {
  body: string;
};

const ReplyForm: React.FC<FormContainerProps> = props => {
  return (
    <FormContainer {...props}>
      <Field
        component={TextField}
        fullWidth
        variant="outlined"
        multiline
        rows={4}
        rowsMax={4}
        name="body"
        label="Body"
      />
    </FormContainer>
  );
};

export default ReplyForm;
