import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";

import PageContainer from "../../components/PageContainer";
import { MemberPageProps, queryGetMember } from "./MemberHelpers";
import { getMember, getMemberVariables } from "./gentypes/getMember";

const useStyles = makeStyles((theme) => ({
  view: {
    width: "100%",
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(2),
  },
  dividerFullWidth: {
    margin: theme.spacing(1, 0, 0, 2),
  },
  listItemIcon: {
    minWidth: theme.spacing(4),
  },
}));

const MemberGet: React.FC = () => {
  const history = useHistory();
  const { memberId } = useParams<MemberPageProps>();
  const classes = useStyles();

  const { loading, error, data } = useQuery<getMember, getMemberVariables>(
    queryGetMember,
    {
      variables: {
        id: memberId!,
      },
    }
  );

  const menuItems = React.useMemo(() => {
    return [
      {
        id: "edit",
        name: "Edit Member",
        help: "Edit member for organization",
        icon: <EditIcon />,
        onInvoke: () => history.push(`edit`),
        fab: true,
      },
      {
        id: "delete",
        name: "Delete Member",
        help: "Delete member for organization",
        icon: <DeleteIcon />,
        onInvoke: () => history.push(`edit`),
      },
    ];
  }, [history]);

  const member = data?.getMember;

  return (
    <PageContainer loading={loading} error={error} menuItems={menuItems}>
      {!member ? (
        false
      ) : (
        <List className={classes.view}>
          <ListItem>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon fontSize='large' />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={member.name} secondary={member.info} />
          </ListItem>
          <Divider component='li' />
          <Box component='li'>
            <Typography
              className={classes.dividerFullWidth}
              color='textSecondary'
              display='block'
              variant='caption'>
              Email
            </Typography>
          </Box>
          <ListItem>
            <ListItemIcon className={classes.listItemIcon}>
              <EmailIcon />
            </ListItemIcon>
            {
              // TODO: add mailto: link for email
            }
            <ListItemText primary={member.email ? member.email : "none"} />
          </ListItem>
        </List>
      )}
    </PageContainer>
  );
};

export default MemberGet;
