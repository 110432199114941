import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import EventForm, { EventFormData } from "./EventForm";
import {
  queryListEvents,
  mutationAddEvent,
  validationSchemaEventInput,
} from "./EventHelpers";
import { listEvents, listEvents_listEvents_edges } from "./gentypes/listEvents";
import { addEvent, addEventVariables } from "./gentypes/addEvent";
import { OwnerPageProps } from "../CommonHelpers";

const defaultEventInput: EventFormData = {
  title: "",
  start: 0,
  end: 0,
  allDay: false,
  location: "",
  body: "",
};

const EventAdd: React.FC = () => {
  const history = useHistory();
  const { orgId, groupId } = useParams<OwnerPageProps>();
  const ownerId = groupId || orgId;

  const [doMutation] = useMutation<addEvent, addEventVariables>(
    mutationAddEvent
  );

  const onSubmit = React.useCallback(
    (values: EventFormData, actions: FormikHelpers<EventFormData>) => {
      doMutation({
        variables: {
          ownerId: ownerId!,
          event: {
            title: values.title,
            start: values.start,
            end: values.end,
            allDay: values.allDay,
            location: values.location,
            body: values.body,
          },
        },
        update: (cache, { data }) => {
          const readData = cache.readQuery<listEvents>({
            query: queryListEvents,
            variables: {
              ownerId: ownerId!,
            },
          });

          // Only add to query if data is present readQuery may throw
          const queryData = readData!.listEvents;
          if (queryData) {
            const edges: listEvents_listEvents_edges[] = [
              {
                __typename: "EventEdge",
                cursor: "",
                node: data!.addEvent!,
              },
              ...queryData!.edges!,
            ];

            const writeData: listEvents = {
              listEvents: {
                __typename: queryData.__typename,
                edges,
                pageInfo: { ...queryData.pageInfo },
              },
            };
            cache.writeQuery({
              query: queryListEvents,
              variables: {
                ownerId: ownerId!,
              },
              data: writeData,
            });
          }
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, ownerId, history]
  );

  const onCancel = () => {
    history.push(`list`);
  };

  return (
    <Formik
      initialValues={defaultEventInput}
      onSubmit={onSubmit}
      validationSchema={validationSchemaEventInput}>
      {({ submitForm, isSubmitting }) => {
        return (
          <EventForm
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            onCancel={onCancel}>
            {}
          </EventForm>
        );
      }}
    </Formik>
  );
};

export default EventAdd;
