import gql from "graphql-tag";
import * as Yup from "yup";
import { OwnerPageProps, pageInfoFragment } from "../CommonHelpers";

export interface SignupPageProps extends OwnerPageProps {
  signupId: string;
}

const signupFragment = gql`
  fragment SignupData on Signup {
    id
    title
    type
    details
    location
    start
    end
    addOn
    addBy
    editOn
    editBy
  }
`;

const signupListFragment = gql`
  fragment SignupListData on SignupConnection {
    edges {
      cursor
      node {
        ...SignupData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
  ${pageInfoFragment}
  ${signupFragment}
`;

export const queryListSignups = gql`
  query listSignups($ownerId: ID!, $range: PaginationInput) {
    listSignups(ownerId: $ownerId, range: $range)
      @connection(key: "signups", filter: ["ownerId"]) {
      ...SignupListData
    }
  }
  ${signupListFragment}
`;

export const queryGetSignup = gql`
  query getSignup($id: ID!) {
    getSignup(id: $id) {
      ...SignupData
    }
  }
  ${signupFragment}
`;

export const mutationAddSignup = gql`
  mutation addSignup($ownerId: ID!, $signup: SignupInput!) {
    addSignup(ownerId: $ownerId, signup: $signup) {
      ...SignupData
    }
  }
  ${signupFragment}
`;

export const mutationEditSignup = gql`
  mutation editSignup($id: ID!, $signup: SignupInput!) {
    editSignup(id: $id, signup: $signup) {
      ...SignupData
    }
  }
  ${signupFragment}
`;

export const validationSchemaSignupInput = Yup.object().shape({
  title: Yup.string().max(256).required(),
  body: Yup.string().max(1024),
});
