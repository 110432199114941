import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ReplyList from "./ReplyList";
import ReplyAdd from "./ReplyAdd";
import ReplyGet from "./ReplyGet";
import ReplyEdit from "./ReplyEdit";

const Reply: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/list`} component={ReplyList} />
      <Route path={`${path}/add`} component={ReplyAdd} />
      <Route path={`${path}/:replyId/get`} component={ReplyGet} />
      <Route path={`${path}/:replyId/edit`} component={ReplyEdit} />
    </Switch>
  );
};

export default Reply;
