import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import LoadingContainer from "../../components/LoadingContainer";
import SignupForm, { SignupFormData } from "./SignupForm";
import {
  queryGetSignup,
  mutationEditSignup,
  validationSchemaSignupInput,
  SignupPageProps,
} from "./SignupHelpers";
import { getSignup, getSignupVariables } from "./gentypes/getSignup";
import { editSignup, editSignupVariables } from "./gentypes/editSignup";

const SignupEdit: React.FC = () => {
  const history = useHistory();
  const { signupId } = useParams<SignupPageProps>();

  const { loading, error, data } = useQuery<getSignup, getSignupVariables>(
    queryGetSignup,
    {
      variables: {
        id: signupId!,
      },
    }
  );

  const initialValues = React.useMemo<SignupFormData | null>(() => {
    const signup = data?.getSignup;
    if (!signup) return null;
    return {
      title: signup.title,
      type: signup.type,
      details: signup.details,
      location: signup.location,
    };
  }, [data]);

  const [doMutation] = useMutation<editSignup, editSignupVariables>(
    mutationEditSignup
  );

  const onSubmit = React.useCallback(
    (values: SignupFormData, actions: FormikHelpers<SignupFormData>) => {
      doMutation({
        variables: {
          id: signupId!,
          signup: {
            title: values.title,
            type: values.type,
            details: values.details,
            location: values.location,
          },
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, signupId, history]
  );

  const onCancel = () => {
    history.push(`get`);
  };

  return (
    <LoadingContainer loading={loading} error={error}>
      {!initialValues ? (
        {}
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaSignupInput}>
          {({ submitForm, isSubmitting }) => {
            return (
              <SignupForm
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                onCancel={onCancel}>
                {}
              </SignupForm>
            );
          }}
        </Formik>
      )}
    </LoadingContainer>
  );
};

export default SignupEdit;
