import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";

import FormContainer, {
  FormContainerProps,
} from "../../components/FormContainer";

export type UserFormData = {
  fullname: string;
  nickname: string | null;
};

const UserForm: React.FC<FormContainerProps> = (props) => {
  return (
    <FormContainer {...props}>
      <Field
        component={TextField}
        fullWidth
        variant='outlined'
        name='fullname'
        type='text'
        label='Full name'
      />
      <Field
        component={TextField}
        fullWidth
        variant='outlined'
        name='nickname'
        type='text'
        label='Nick name'
      />
    </FormContainer>
  );
};

export default UserForm;
