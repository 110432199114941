import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import MemberForm, { MemberFormData } from "./MemberForm";
import {
  queryListMembers,
  mutationAddMember,
  validationSchemaMemberInput,
} from "./MemberHelpers";
import { MemberType, MemberRole } from "./../../globalTypes.d";
import {
  listMembers,
  listMembers_listMembers_edges,
} from "./gentypes/listMembers";
import { addMember, addMemberVariables } from "./gentypes/addMember";
import { OrgPageProps } from "../Org/OrgHelpers";

export const defaultMemberInput: MemberFormData = {
  name: "",
  info: "",
  email: "",
  role: MemberRole.MEMBER,
  type: MemberType.ADULT,
};

const MemberAdd: React.FC = () => {
  const history = useHistory();
  const { orgId } = useParams<OrgPageProps>();

  const [doMutation] = useMutation<addMember, addMemberVariables>(
    mutationAddMember
  );

  const onSubmit = React.useCallback(
    (values: MemberFormData, actions: FormikHelpers<MemberFormData>) => {
      doMutation({
        variables: {
          orgId: orgId!,
          member: {
            name: values.name,
            info: values.info,
            email: values.email,
            role: values.role,
            type: values.type,
          },
        },
        update: (cache, { data }) => {
          const readData = cache.readQuery<listMembers>({
            query: queryListMembers,
            variables: {
              orgId: orgId!,
            },
          });

          // Only add to query if data is present readQuery may throw
          const queryData = readData!.listMembers;
          if (queryData) {
            const edges: listMembers_listMembers_edges[] = [
              {
                __typename: "MemberEdge",
                cursor: "",
                node: data!.addMember!,
              },
              ...queryData!.edges!,
            ];

            const writeData: listMembers = {
              listMembers: {
                __typename: queryData.__typename,
                edges,
                pageInfo: { ...queryData.pageInfo },
              },
            };
            cache.writeQuery({
              query: queryListMembers,
              variables: {
                orgId: orgId!,
              },
              data: writeData,
            });
          }
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, orgId, history]
  );

  const onCancel = () => {
    history.push(`list`);
  };

  return (
    <Formik
      initialValues={defaultMemberInput}
      onSubmit={onSubmit}
      validationSchema={validationSchemaMemberInput}>
      {({ submitForm, isSubmitting }) => {
        return (
          <MemberForm
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            onCancel={onCancel}>
            {}
          </MemberForm>
        );
      }}
    </Formik>
  );
};

export default MemberAdd;
