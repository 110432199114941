import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import SignupForm, { SignupFormData } from "./SignupForm";
import {
  queryListSignups,
  mutationAddSignup,
  validationSchemaSignupInput,
} from "./SignupHelpers";
import {
  listSignups,
  listSignups_listSignups_edges,
} from "./gentypes/listSignups";
import { addSignup, addSignupVariables } from "./gentypes/addSignup";
import { SignupType } from "./../../globalTypes.d";
import { OwnerPageProps } from "../CommonHelpers";

const defaultSignupInput: SignupFormData = {
  title: "",
  type: SignupType.EVENT,
  details: "",
  location: "",
};

const SignupAdd: React.FC = () => {
  const history = useHistory();
  const { orgId, groupId } = useParams<OwnerPageProps>();
  const ownerId = groupId || orgId;

  const [doMutation] = useMutation<addSignup, addSignupVariables>(
    mutationAddSignup
  );

  const onSubmit = React.useCallback(
    (values: SignupFormData, actions: FormikHelpers<SignupFormData>) => {
      doMutation({
        variables: {
          ownerId: ownerId!,
          signup: {
            title: values.title,
            type: values.type,
            details: values.details,
            location: values.location,
          },
        },
        update: (cache, { data }) => {
          const readData = cache.readQuery<listSignups>({
            query: queryListSignups,
            variables: {
              ownerId: ownerId!,
            },
          });

          // Only add to query if data is present readQuery may throw
          const queryData = readData!.listSignups;
          if (queryData) {
            const edges: listSignups_listSignups_edges[] = [
              {
                __typename: "SignupEdge",
                cursor: "",
                node: data!.addSignup!,
              },
              ...queryData!.edges!,
            ];

            const writeData: listSignups = {
              listSignups: {
                __typename: queryData.__typename,
                edges,
                pageInfo: { ...queryData.pageInfo },
              },
            };
            cache.writeQuery({
              query: queryListSignups,
              variables: {
                ownerId: ownerId!,
              },
              data: writeData,
            });
          }
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, ownerId, history]
  );

  const onCancel = () => {
    history.push(`list`);
  };

  return (
    <Formik
      initialValues={defaultSignupInput}
      onSubmit={onSubmit}
      validationSchema={validationSchemaSignupInput}>
      {({ submitForm, isSubmitting }) => {
        return (
          <SignupForm
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            onCancel={onCancel}>
            {}
          </SignupForm>
        );
      }}
    </Formik>
  );
};

export default SignupAdd;
