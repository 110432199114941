import React from "react";
import { Field } from "formik";
import { Select } from "formik-material-ui";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormContainer, {
  FormContainerProps
} from "./../../../components/FormContainer";
import { MemberRole } from "./../../../globalTypes";

import { memberRole } from "./GroupMemberHelpers";

export type GroupMemberFormData = {
  id: string;
  role: MemberRole;
};

export type MemberListData = {
  id: string;
  name: string;
  info?: string | null;
  photoUrl?: string | null;
};

interface MemberEditPageProps extends FormContainerProps {
  memberData?: MemberListData[];
}

const GroupMemberForm: React.FC<MemberEditPageProps> = ({
  memberData,
  ...props
}) => {
  const roleInputLabel = React.useRef<HTMLLabelElement>(null);
  const [roleLabelWidth, setRoleLabelWidth] = React.useState(0);
  const memberInputLabel = React.useRef<HTMLLabelElement>(null);
  const [memberLabelWidth, setMemberLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setRoleLabelWidth(roleInputLabel.current!.offsetWidth);
    setMemberLabelWidth(memberInputLabel.current!.offsetWidth);
  }, [roleInputLabel, memberInputLabel]);

  return (
    <FormContainer {...props}>
      {memberData && (
        <FormControl fullWidth variant="outlined">
          <InputLabel ref={memberInputLabel} id="member-label">
            Member
          </InputLabel>
          <Field
            component={Select}
            name="id"
            displayEmpty={true}
            labelId="member-label"
            labelWidth={memberLabelWidth}
          >
            {memberData?.map((value, index) => (
              <MenuItem key={value.id} value={value.id}>
                {`${value.name}`}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
      )}
      <FormControl fullWidth variant="outlined">
        <InputLabel ref={roleInputLabel} shrink id="role-label">
          Role
        </InputLabel>
        <Field
          component={Select}
          name="role"
          labelId="role-label"
          labelWidth={roleLabelWidth}
        >
          {memberRole.map((value, index) => (
            <MenuItem key={value.type} value={value.type}>
              {value.name}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    </FormContainer>
  );
};

export default GroupMemberForm;
