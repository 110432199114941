import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";

import FormContainer, {
  FormContainerProps
} from "../../components/FormContainer";

export type TopicFormData = {
  title: string;
  body?: string;
};

const TopicForm: React.FC<FormContainerProps> = props => {
  return (
    <FormContainer {...props}>
      <Field
        component={TextField}
        fullWidth
        variant="outlined"
        name="title"
        type="text"
        label="Title"
      />
      <Field
        component={TextField}
        fullWidth
        variant="outlined"
        multiline
        rows={4}
        rowsMax={4}
        name="body"
        label="Body"
      />
    </FormContainer>
  );
};

export default TopicForm;
