import React from "react";
import { Field } from "formik";
import { TextField, Select } from "formik-material-ui";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormContainer, {
  FormContainerProps
} from "../../components/FormContainer";
import { memberRole, memberType } from "./MemberHelpers";
import { MemberType, MemberRole, MemberEmailLevel } from "./../../globalTypes";

export type MemberFormData = {
  name: string;
  info?: string | null;
  type: MemberType;
  role: MemberRole;
  email?: string | null;
  emailLevel?: MemberEmailLevel | null;
};

const MemberForm: React.FC<FormContainerProps> = props => {
  const roleInputLabel = React.useRef<HTMLLabelElement>(null);
  const [roleLabelWidth, setRoleLabelWidth] = React.useState(0);
  const typeInputLabel = React.useRef<HTMLLabelElement>(null);
  const [typeLabelWidth, setTypeLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setRoleLabelWidth(roleInputLabel.current!.offsetWidth);
    setTypeLabelWidth(typeInputLabel.current!.offsetWidth);
  }, [roleInputLabel, typeInputLabel]);

  return (
    <FormContainer {...props}>
      <Field
        component={TextField}
        fullWidth
        variant="outlined"
        name="name"
        type="text"
        label="Name"
        helperText="Required"
      />
      <Field
        component={TextField}
        fullWidth
        variant="outlined"
        name="info"
        type="text"
        label="Info"
      />
      <Field
        component={TextField}
        fullWidth
        variant="outlined"
        name="email"
        type="email"
        label="Email"
      />
      <FormControl fullWidth variant="outlined">
        <InputLabel ref={roleInputLabel} shrink id="role-label">
          Role
        </InputLabel>
        <Field
          component={Select}
          name="role"
          labelId="role-label"
          labelWidth={roleLabelWidth}
        >
          {memberRole.map((value, index) => (
            <MenuItem key={value.type} value={value.type}>
              {value.name}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel ref={typeInputLabel} shrink id="type-label">
          Type
        </InputLabel>
        <Field
          component={Select}
          name="type"
          labelId="type-label"
          labelWidth={typeLabelWidth}
        >
          {memberType.map((value, index) => (
            <MenuItem key={value.type} value={value.type}>
              {value.name}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    </FormContainer>
  );
};

export default MemberForm;
