import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";

import LoadingContainer, { LoadingContainerProps } from "./LoadingContainer";
import { useAppBarModel, AppMenuItem } from "../utils/appBarContext";

const useStyles = makeStyles(theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

export interface PageContainerProps extends LoadingContainerProps {
  title?: string;
  menuItems?: AppMenuItem[];
}

const PageContainer: React.FC<PageContainerProps> = ({
  title,
  menuItems,
  children,
  ...containerProps
}) => {
  const classes = useStyles();
  const appBarModel = useAppBarModel();

  React.useEffect(() => {
    appBarModel.addMenuItems(menuItems);
    if (title) {
      appBarModel.setTitle(title);
    }
  }, [title, appBarModel, menuItems]);

  return (
    <LoadingContainer {...containerProps}>
      {!menuItems
        ? null
        : menuItems.map(item => {
            if (item.fab) {
              return (
                <Fab
                  key={item.id}
                  className={classes.fab}
                  color="primary"
                  aria-label={item.name}
                  onClick={item.onInvoke}
                >
                  {item.icon}
                </Fab>
              );
            }
            return null;
          })}
      {children}
    </LoadingContainer>
  );
};

export default PageContainer;
