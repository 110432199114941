import React from "react";

export interface AppMenuItem {
  id: string;
  fab?: boolean;
  name: string;
  help: string;
  icon: React.ReactElement;
  onInvoke(event: React.MouseEvent<HTMLElement>): void;
}

export interface AppBarModel {
  setTitle(title: string): void;
  addMenuItems(items?: AppMenuItem[]): void;
}

export const AppBarContext = React.createContext<AppBarModel>({
  setTitle() {},
  addMenuItems() {}
});

export const useAppBarModel = (): AppBarModel => {
  return React.useContext(AppBarContext);
};
