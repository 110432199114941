/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Access {
  FULL = "FULL",
  MODIFY = "MODIFY",
  NONE = "NONE",
  READ = "READ",
  READWRITE = "READWRITE",
  WRITE = "WRITE"
}

export enum FeatureType {
  EVENT = "EVENT",
  EVENTRESPONSE = "EVENTRESPONSE",
  GROUP = "GROUP",
  MEMBER = "MEMBER",
  ORG = "ORG",
  SIGNUP = "SIGNUP",
  SIGNUPSLOTS = "SIGNUPSLOTS",
  TOPIC = "TOPIC",
  TOPICREPLY = "TOPICREPLY"
}

export enum MemberEmailLevel {
  CASUAL = "CASUAL",
  CRITICAL = "CRITICAL",
  FAMILY = "FAMILY",
  IMPORTANT = "IMPORTANT",
  NEVER = "NEVER",
  NORMAL = "NORMAL",
  PERSONAL = "PERSONAL"
}

export enum MemberRole {
  ADMIN = "ADMIN",
  BANNED = "BANNED",
  FAN = "FAN",
  GUEST = "GUEST",
  LEADER = "LEADER",
  MEMBER = "MEMBER",
  MOTERATOR = "MOTERATOR",
  NONE = "NONE",
  OWNER = "OWNER",
  PARTICIPANT = "PARTICIPANT",
  PUBLIC = "PUBLIC",
  TRIAL = "TRIAL"
}

export enum MemberType {
  ADULT = "ADULT",
  UNDER13 = "UNDER13",
  UNDER18 = "UNDER18"
}

export enum SignupType {
  EVENT = "EVENT",
  NEED = "NEED",
  RECURRING = "RECURRING",
  TIMESLOTS = "TIMESLOTS"
}

export interface EventInput {
  title: string;
  start: number;
  end: number;
  allDay?: boolean | null;
  location?: string | null;
  body?: string | null;
}

export interface FeatureAccessInput {
  feature: FeatureType;
  item?: MemberOwnerAccessInput | null;
  list?: MemberOwnerAccessInput | null;
}

export interface GroupInput {
  title: string;
  desc?: string | null;
  owner?: string | null;
}

export interface MemberAccessInput {
  role: MemberRole;
  access?: (FeatureAccessInput | null)[] | null;
}

export interface MemberInput {
  name: string;
  info?: string | null;
  type: MemberType;
  role: MemberRole;
  email?: string | null;
  emailLevel?: MemberEmailLevel | null;
}

export interface MemberOwnerAccessInput {
  member?: Access | null;
  owner?: Access | null;
}

export interface OrgInput {
  title: string;
  desc?: string | null;
}

export interface PaginationInput {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

export interface ReplyInput {
  body: string;
}

export interface SignupInput {
  title: string;
  type: SignupType;
  details?: string | null;
  location?: string | null;
  start?: number | null;
  end?: number | null;
  opened?: number | null;
  closed?: number | null;
  contacts?: string[] | null;
  events?: string[] | null;
}

export interface TopicInput {
  title: string;
  body?: string | null;
}

export interface UserInput {
  email?: string | null;
  phone?: string | null;
  fullname: string;
  nickname?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
