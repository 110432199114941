import gql from "graphql-tag";
import * as Yup from "yup";
//import { pageInfoFragment } from "../CommonHelpers";

export const pageInfoFragment = gql`
  fragment PageInfoData on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;

const userFragment = gql`
  fragment UserData on User {
    id
    email
    phone
    fullname
    nickname
  }
`;

export const queryGetUser = gql`
  query getUser {
    getUser {
      ...UserData
    }
  }
  ${userFragment}
`;

export const mutationAddUser = gql`
  mutation addUser($user: UserInput!) {
    addUser(user: $user) {
      ...UserData
    }
  }
  ${userFragment}
`;

export const mutationEditUser = gql`
  mutation editUser($user: UserInput!) {
    editUser(user: $user) {
      ...UserData
    }
  }
  ${userFragment}
`;

export const validationSchemaUserInput = Yup.object().shape({
  email: Yup.string().email(),
  fullname: Yup.string()
    .max(256)
    .required(),
  nickname: Yup.string()
    .max(256)
    .required()
});

export const memberOrgFragment = gql`
  fragment MemberOrgData on MemberOrg {
    id
    title
    desc
  }
`;

export const memberOrgListFragment = gql`
  fragment MemberOrgListData on MemberOrgConnection {
    edges {
      cursor
      node {
        ...MemberOrgData
      }
    }
    pageInfo {
      ...PageInfoData
    }
  }
  ${pageInfoFragment}
  ${memberOrgFragment}
`;

export const queryGetUserAndListOrgs = gql`
  query getUserAndListOrgs($range: PaginationInput) {
    getUser {
      ...UserData
    }
    listMemberOrgsForUser(range: $range) @connection(key: "memberOrg") {
      ...MemberOrgListData
    }
  }
  ${userFragment}
  ${memberOrgListFragment}
`;
