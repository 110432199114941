import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import GroupMemberList from "./GroupMemberList";
import GroupMemberGet from "./GroupMemberGet";
import GroupMemberAdd from "./GroupMemberAdd";
import GroupMemberEdit from "./GroupMemberEdit";

const GroupMember: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/list`} component={GroupMemberList} />
      <Route path={`${path}/add`} component={GroupMemberAdd} />
      <Route path={`${path}/:memberId/get`} component={GroupMemberGet} />
      <Route path={`${path}/:memberId/edit`} component={GroupMemberEdit} />
      <Route exact path={`${path}/:memberId/`} component={GroupMemberGet} />
      <Route exact path={`${path}/`} component={GroupMemberList} />
    </Switch>
  );
};

export default GroupMember;
