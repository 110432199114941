import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";

import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import AddIcon from "@material-ui/icons/Add";
import MailIcon from "@material-ui/icons/Mail";
import RefreshIcon from "@material-ui/icons/Refresh";

import { queryListSignups } from "./SignupHelpers";
import { listSignups, listSignupsVariables } from "./gentypes/listSignups";
import { mergePageInfo, OwnerPageProps } from "../CommonHelpers";
import PageListContainer from "../../components/PageListContainer";

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: 0,
    padding: theme.spacing(0, 1, 0, 1),
  },
  inline: {
    maxHeight: "40px",
    height: "40px",
    overflowY: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    whiteSpace: "nowrap",
  },
}));

const pageSize = 5;

const SignupList: React.FC = () => {
  const history = useHistory();
  const { orgId, groupId } = useParams<OwnerPageProps>();
  const ownerId = groupId || orgId;
  const classes = useStyles();

  const { loading, error, data, fetchMore, refetch } = useQuery<
    listSignups,
    listSignupsVariables
  >(queryListSignups, {
    variables: {
      ownerId: ownerId!,
      range: { first: pageSize },
    },
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
  });

  const onLoadMore = React.useCallback(() => {
    const pageInfo = data?.listSignups?.pageInfo;
    if (!pageInfo || !pageInfo.hasNextPage || !pageInfo.endCursor) return data;

    return fetchMore({
      variables: {
        range: {
          first: pageSize,
          after: pageInfo.endCursor,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        const prevData = previousResult.listSignups;
        const nextData = fetchMoreResult.listSignups;
        const edges = nextData?.edges?.length
          ? [...prevData!.edges!, ...nextData.edges]
          : prevData.edges;
        return {
          // Put the new comments at the end of the list and update `pageInfo`
          // so we have the new `endCursor` and `hasNextPage` values
          listSignups: {
            __typename: prevData.__typename,
            edges: edges,
            pageInfo: mergePageInfo(prevData.pageInfo, nextData.pageInfo),
          },
        };
      },
    });
  }, [data, fetchMore]);

  const onRefresh = React.useCallback(() => {
    if (!loading) {
      refetch();
    }
  }, [refetch, loading]);

  const menuItems = React.useMemo(() => {
    return [
      {
        id: "add",
        name: "Add Signup",
        help: "Add signup to organization",
        icon: <AddIcon />,
        onInvoke: () => history.push(`add`),
        fab: true,
      },
      {
        id: "reload",
        name: "Reload Signups",
        help: "Reload signup list",
        icon: <RefreshIcon />,
        onInvoke: () => onRefresh(),
      },
    ];
  }, [onRefresh, history]);

  const hasNextPage = React.useMemo(() => {
    return data?.listSignups?.pageInfo?.hasNextPage === true;
  }, [data]);

  const listData = data?.listSignups?.edges;

  return (
    <PageListContainer
      loading={loading}
      error={error}
      hasData={data !== undefined}
      hasNextPage={hasNextPage}
      onLoadMore={onLoadMore}
      menuItems={menuItems}>
      {!listData
        ? false
        : listData.map(({ node }, index) => {
            return (
              <ListItem
                key={node.id}
                button
                disableGutters
                alignItems='flex-start'
                divider={index !== listData.length - 1}
                onClick={() => history.push(`${node.id}/get`)}>
                <Box display='flex' flexDirection='column'>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Badge
                      color='secondary'
                      variant='dot'
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}>
                      <MailIcon fontSize='small' />
                    </Badge>
                  </ListItemIcon>
                </Box>
                <ListItemText
                  primary={`${node.start} to ${node.end}`}
                  secondaryTypographyProps={{ className: classes.inline }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        className={classes.inline}
                        component='span'
                        variant='body2'
                        color='textPrimary'>
                        {node.title}
                        {` — ${node.details} Do you have Paris recommendations? Have
                      you ever. Wish I could come, but I'm out of town this.
                      Wish I could come, but I'm out of town this. Wish I could
                      come, but I'm out of town this Wish I could come, but I'm
                      out of town this Wish I could come, but I'm out of town
                      this`}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            );
          })}
    </PageListContainer>
  );
};

export default SignupList;
