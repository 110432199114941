import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import ReplyForm, { ReplyFormData } from "./ReplyForm";
import {
  queryListReplies,
  mutationAddReply,
  validationSchemaReplyInput,
} from "./ReplyHelpers";
import {
  listReplies,
  listReplies_listReplies_edges,
} from "./gentypes/listReplies";
import { addReply, addReplyVariables } from "./gentypes/addReply";
import { TopicPageProps } from "../TopicHelpers";

const defaultReplyInput: ReplyFormData = {
  body: "",
};

const ReplyAdd: React.FC = () => {
  const history = useHistory();
  const { topicId } = useParams<TopicPageProps>();

  const [doMutation] = useMutation<addReply, addReplyVariables>(
    mutationAddReply
  );

  const onSubmit = React.useCallback(
    (values: ReplyFormData, actions: FormikHelpers<ReplyFormData>) => {
      doMutation({
        variables: {
          topicId: topicId!,
          reply: {
            body: values.body,
          },
        },
        update: (cache, { data }) => {
          const readData = cache.readQuery<listReplies>({
            query: queryListReplies,
            variables: {
              topicId: topicId!,
            },
          });

          // Only add to query if data is present readQuery may throw
          const queryData = readData!.listReplies;
          if (queryData) {
            const edges: listReplies_listReplies_edges[] = [
              {
                __typename: "ReplyEdge",
                cursor: "",
                node: data!.addReply!,
              },
              ...queryData!.edges!,
            ];

            const writeData: listReplies = {
              listReplies: {
                __typename: queryData.__typename,
                edges,
                pageInfo: { ...queryData.pageInfo },
              },
            };
            cache.writeQuery({
              query: queryListReplies,
              variables: {
                topicId: topicId!,
              },
              data: writeData,
            });
          }
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, topicId, history]
  );

  const onCancel = () => {
    history.goBack();
  };

  return (
    <Formik
      initialValues={defaultReplyInput}
      onSubmit={onSubmit}
      validationSchema={validationSchemaReplyInput}>
      {({ submitForm, isSubmitting }) => {
        return (
          <ReplyForm
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            onCancel={onCancel}>
            {}
          </ReplyForm>
        );
      }}
    </Formik>
  );
};

export default ReplyAdd;
