import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import OrgForm, { OrgFormData } from "./OrgForm";
import {
  queryListMemberOrgsForUser,
  mutationAddOrg,
  validationSchemaOrgInput,
} from "./OrgHelpers";
import {
  listMemberOrgsForUser,
  listMemberOrgsForUser_listMemberOrgsForUser_edges,
  listMemberOrgsForUser_listMemberOrgsForUser_edges_node,
} from "./gentypes/listMemberOrgsForUser";
import { addOrg, addOrgVariables } from "./gentypes/addOrg";

const defaultOrgInput: OrgFormData = {
  title: "",
  desc: "",
};

const OrgAdd: React.FC = () => {
  const history = useHistory();
  const [doMutation] = useMutation<addOrg, addOrgVariables>(mutationAddOrg);

  const onSubmit = React.useCallback(
    (values: OrgFormData, actions: FormikHelpers<OrgFormData>) => {
      doMutation({
        variables: {
          org: {
            title: values.title,
            desc: values.desc,
          },
        },
        update: (cache, { data }) => {
          const readData = cache.readQuery<listMemberOrgsForUser>({
            query: queryListMemberOrgsForUser,
          });

          const memberOrgNode: listMemberOrgsForUser_listMemberOrgsForUser_edges_node = {
            __typename: "MemberOrg",
            id: data!.addOrg.id,
            title: data!.addOrg.title,
            desc: data!.addOrg.desc,
          };

          // Only add to query if data is present readQuery may throw
          const queryData = readData!.listMemberOrgsForUser;
          if (queryData) {
            const edges: listMemberOrgsForUser_listMemberOrgsForUser_edges[] = [
              {
                __typename: "MemberOrgEdge",
                cursor: "",
                node: memberOrgNode,
              },
              ...queryData!.edges!,
            ];

            const writeData: listMemberOrgsForUser = {
              listMemberOrgsForUser: {
                __typename: queryData.__typename,
                edges,
                pageInfo: { ...queryData.pageInfo },
              },
            };
            cache.writeQuery({
              query: queryListMemberOrgsForUser,
              data: writeData,
            });
          }
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, history]
  );

  const onCancel = () => {
    history.push("/");
  };

  return (
    <Formik
      initialValues={defaultOrgInput}
      onSubmit={onSubmit}
      validationSchema={validationSchemaOrgInput}>
      {({ submitForm, isSubmitting }) => {
        return (
          <OrgForm
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            onCancel={onCancel}>
            {}
          </OrgForm>
        );
      }}
    </Formik>
  );
};

export default OrgAdd;
