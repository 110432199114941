import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import LoadingContainer from "../../components/LoadingContainer";
import GroupForm, { GroupFormData } from "./GroupForm";
import {
  queryGetGroup,
  mutationAddGroup,
  validationSchemaGroupInput,
  GroupPageProps,
} from "./GroupHelpers";
import { getGroup, getGroupVariables } from "./gentypes/getGroup";
import { editGroup, editGroupVariables } from "./gentypes/editGroup";

const GroupEdit: React.FC = () => {
  const history = useHistory();
  const { groupId } = useParams<GroupPageProps>();

  const { loading, error, data } = useQuery<getGroup, getGroupVariables>(
    queryGetGroup,
    {
      variables: {
        id: groupId!,
      },
    }
  );

  const initialValues = React.useMemo<GroupFormData | null>(() => {
    const group = data?.getGroup;
    if (!group) return null;
    return { title: group.title, desc: group.desc != null ? group.desc : "" };
  }, [data]);

  const [doMutation] = useMutation<editGroup, editGroupVariables>(
    mutationAddGroup
  );

  const onSubmit = React.useCallback(
    (values: GroupFormData, actions: FormikHelpers<GroupFormData>) => {
      doMutation({
        variables: {
          id: groupId!,
          group: {
            title: values.title,
            desc: values.desc,
          },
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, groupId, history]
  );

  const onCancel = () => {
    history.push(`get`);
  };

  return (
    <LoadingContainer loading={loading} error={error}>
      {!initialValues ? (
        {}
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaGroupInput}>
          {({ submitForm, isSubmitting }) => {
            return (
              <GroupForm
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                onCancel={onCancel}>
                {}
              </GroupForm>
            );
          }}
        </Formik>
      )}
    </LoadingContainer>
  );
};

export default GroupEdit;
