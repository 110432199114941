import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";

import LoadingContainer from "../../../components/LoadingContainer";
import GroupMemberForm, {
  GroupMemberFormData,
  MemberListData,
} from "./GroupMemberForm";
import { queryGetMember } from "../../Member/MemberHelpers";
import { getMember, getMemberVariables } from "../../Member/gentypes/getMember";
import {
  GroupMemberPageProps,
  mutationEditMemberAccessInGroup,
  validationSchemaMemberInput,
} from "./GroupMemberHelpers";
import {
  editMemberAccessInGroup,
  editMemberAccessInGroupVariables,
} from "./gentypes/editMemberAccessInGroup";

const GroupMemberEdit: React.FC = () => {
  const history = useHistory();
  const { groupId, memberId } = useParams<GroupMemberPageProps>();

  const { loading, error, data } = useQuery<getMember, getMemberVariables>(
    queryGetMember,
    {
      variables: {
        id: memberId!,
      },
    }
  );

  const initialValues = React.useMemo<GroupMemberFormData | null>(() => {
    const group = data?.getMember?.groups?.find(
      (item) => item.groupId === groupId
    );
    if (!group) return null;
    return {
      id: data!.getMember.id,
      role: group.role,
    };
  }, [data, groupId]);

  const memberData = React.useMemo<MemberListData[] | null>(() => {
    const member = data?.getMember;
    if (!member) return null;
    return [
      {
        id: member.id,
        name: member.name,
        info: member.info,
        photoUrl: member.photoUrl,
      },
    ];
  }, [data]);

  const [doMutation] = useMutation<
    editMemberAccessInGroup,
    editMemberAccessInGroupVariables
  >(mutationEditMemberAccessInGroup);

  const onSubmit = React.useCallback(
    (
      values: GroupMemberFormData,
      actions: FormikHelpers<GroupMemberFormData>
    ) => {
      doMutation({
        variables: {
          id: memberId!,
          groupId: groupId!,
          access: { role: values.role },
        },
      })
        .then((res) => {
          actions.setSubmitting(false);
          history.goBack();
        })
        .catch((err) => {
          actions.setSubmitting(false);
          //actions.setErrors({});
          actions.setStatus({ msg: "Submit failed... try again" });
        });
    },
    [doMutation, memberId, groupId, history]
  );

  const onCancel = () => {
    history.goBack();
  };

  return (
    <LoadingContainer loading={loading} error={error}>
      {!initialValues || !memberData ? (
        {}
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaMemberInput}>
          {({ submitForm, isSubmitting }) => {
            return (
              <GroupMemberForm
                memberData={memberData}
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                onCancel={onCancel}>
                {}
              </GroupMemberForm>
            );
          }}
        </Formik>
      )}
    </LoadingContainer>
  );
};

export default GroupMemberEdit;
