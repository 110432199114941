import React from "react";
import { ApolloError } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container, { ContainerProps } from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  loading: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(3),
    "& > *": {
      margin: theme.spacing(1)
    }
  }
}));

export interface LoadingContainerProps extends ContainerProps {
  loading?: boolean;
  error?: ApolloError;
  hasData?: boolean;
}

const LoadingContainer: React.FC<LoadingContainerProps> = ({
  loading,
  error,
  hasData = false,
  children,
  maxWidth = "sm",
  disableGutters = true,
  ...containerProps
}) => {
  const classes = useStyles();

  return (
    <Container
      maxWidth={maxWidth}
      disableGutters={disableGutters}
      {...containerProps}
    >
      {!hasData && loading ? (
        <Box className={classes.loading}>
          <CircularProgress />
          <Typography>Loading...</Typography>
        </Box>
      ) : null}
      {error ? <Typography>`Error! ${error.message}`</Typography> : null}
      {children}
    </Container>
  );
};

export default LoadingContainer;
