import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import EventList from "./EventList";
import EventAdd from "./EventAdd";
import EventGet from "./EventGet";
import EventEdit from "./EventEdit";
import Response from "./Response/Response";

const Event: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/list`} component={EventList} />
      <Route path={`${path}/add`} component={EventAdd} />
      <Route path={`${path}/:eventId/get`} component={EventGet} />
      <Route path={`${path}/:eventId/edit`} component={EventEdit} />
      <Route path={`${path}/:eventId/response`} component={Response} />
      <Route exact path={`${path}/:eventId/`} component={EventGet} />
      <Route exact path={`${path}/`} component={EventList} />
    </Switch>
  );
};

export default Event;
