import React from "react";
import {
  Switch,
  //useHistory,
  //useParams,
} from "react-router-dom";

//import ReplyList from "./ReplyList";
//import ReplyAdd from "./ReplyAdd";
//import ReplyGet from "./ReplyGet";
//import ReplyEdit from "./ReplyEdit";

//import { useAppBarModel } from "../../utils/appBarContext";

/*
interface ResponsePageProps {
  signupId: string;
}
*/

const Response: React.FC = () => {
  //const history = useHistory();
  //const { signupId } = useParams<ResponsePageProps>();
  /*
  return (
    <Switch>
      <ReplyList topicId={topicId} path="list" />
      <ReplyAdd topicId={topicId} path="add" />
      <ReplyGet path=":id/get" />
      <ReplyEdit path=":id/edit" />
    </Switch>
  );
  */
  return <Switch></Switch>;
};

export default Response;
