import React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";

import GroupGet from "./GroupGet";
import GroupEdit from "./GroupEdit";
import GroupMember from "./Member/GroupMember";
import Topic from "../Topic/Topic";
import Event from "../Event/Event";
import Signup from "../Signup/Signup";
import { GroupPageProps, queryGetGroupInfo } from "./GroupHelpers";
import { useAppBarModel } from "../../utils/appBarContext";
import { getGroupInfo, getGroupInfoVariables } from "./gentypes/getGroupInfo";

const GroupRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  const { groupId } = useParams<GroupPageProps>();
  const appBarModel = useAppBarModel();

  // Use getGroupInfo since it will be cashed with GroupGet.tsx
  const { data } = useQuery<getGroupInfo, getGroupInfoVariables>(
    queryGetGroupInfo,
    {
      variables: {
        id: groupId!,
      },
    }
  );

  React.useEffect(() => {
    // Call setTitle within useEffect to avoid updates during render.
    // See "Warning: Cannot update a component from inside the function body of a different component."
    // in https://reactjs.org/blog/2020/02/26/react-v16.13.0.html
    const title = data?.getGroup?.title;
    if (title) {
      appBarModel.setTitle(title);
    }
  }, [appBarModel, data]);

  return (
    <Switch>
      <Route path={`${path}/get`} component={GroupGet} />
      <Route path={`${path}/edit`} component={GroupEdit} />
      <Route path={`${path}/member`} component={GroupMember} />
      <Route path={`${path}/topic`} component={Topic} />
      <Route path={`${path}/event`} component={Event} />
      <Route path={`${path}/signup`} component={Signup} />
      <Route exact path={`${path}/`} component={GroupGet} />
    </Switch>
  );
};

export default GroupRoutes;
