import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import EventIcon from "@material-ui/icons/Event";
import GroupIcon from "@material-ui/icons/Group";

import PageContainer from "../../components/PageContainer";
import { GroupPageProps, queryGetGroupInfo } from "./GroupHelpers";
import { getGroupInfo, getGroupInfoVariables } from "./gentypes/getGroupInfo";

const GroupGet: React.FC = () => {
  const history = useHistory();
  const { groupId } = useParams<GroupPageProps>();

  const { loading, error, data } = useQuery<
    getGroupInfo,
    getGroupInfoVariables
  >(queryGetGroupInfo, {
    variables: {
      id: groupId!,
    },
  });

  const listData = React.useMemo(() => {
    const info = data?.getGroupInfo;
    if (!info) return null;
    return [
      {
        id: "member",
        title: "Members",
        icon: () => <GroupIcon fontSize='large' color='primary' />,
        badge: info.members.totalCount,
      },
      {
        id: "topic",
        title: "Messages",
        icon: () => <EmailIcon fontSize='large' color='primary' />,
        badge: info.topics.totalCount,
      },
      {
        id: "event",
        title: "Events",
        icon: () => <EventIcon fontSize='large' color='primary' />,
        badge: info.events.totalCount,
      },
      {
        id: "signup",
        title: "Signups",
        icon: () => <AssignmentIndIcon fontSize='large' color='primary' />,
        badge: info.signups.totalCount,
      },
    ];
  }, [data]);

  const menuItems = React.useMemo(() => {
    return [
      {
        id: "edit",
        name: "Edit Group",
        help: "Edit group for organization",
        icon: <EditIcon />,
        onInvoke: () => history.push(`edit`),
      },
      {
        id: "delete",
        name: "Delete group",
        help: "Delete group for organization",
        icon: <DeleteIcon />,
        onInvoke: () => history.push(`edit`),
      },
    ];
  }, [history]);

  return (
    <PageContainer loading={loading} error={error} menuItems={menuItems}>
      <List>
        {listData?.map((node, index) => {
          return (
            <ListItem
              key={node.id}
              button
              divider
              onClick={() => history.push(`${node.id}/list`)}>
              <ListItemIcon>
                <Badge badgeContent={node.badge} color='secondary'>
                  {node.icon()}
                </Badge>
              </ListItemIcon>
              <ListItemText primary={node.title} />
              <ArrowForwardIosIcon />
            </ListItem>
          );
        })}
      </List>
    </PageContainer>
  );
};

export default GroupGet;
