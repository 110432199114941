import React from "react";
import { Route, useParams, Switch, useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";

import OrgGet from "./OrgGet";
import OrgEdit from "./OrgEdit";
import Member from "../Member/Member";
import Group from "../Group/Group";
import Topic from "../Topic/Topic";
import Event from "../Event/Event";
import Signup from "../Signup/Signup";

import { useAppBarModel } from "../../utils/appBarContext";
import { OrgPageProps, queryGetOrgInfo } from "./OrgHelpers";
import { getOrgInfo, getOrgInfoVariables } from "./gentypes/getOrgInfo";

const Org: React.FC = () => {
  const { path } = useRouteMatch();
  const { orgId } = useParams<OrgPageProps>();
  const appBarModel = useAppBarModel();

  // Use getOrgInfo since it will be cached in OrgGet.tsx
  const { data } = useQuery<getOrgInfo, getOrgInfoVariables>(queryGetOrgInfo, {
    variables: {
      id: orgId!,
    },
  });

  React.useEffect(() => {
    // Call setTitle within useEffect to avoid updates during render.
    // See "Warning: Cannot update a component from inside the function body of a different component."
    // in https://reactjs.org/blog/2020/02/26/react-v16.13.0.html
    const title = data?.getOrg?.title;
    if (title) {
      appBarModel.setTitle(title);
    }
  }, [appBarModel, data]);

  return (
    <Switch>
      <Route path={`${path}/get`} component={OrgGet} />
      <Route path={`${path}/edit`} component={OrgEdit} />
      <Route path={`${path}/member`} component={Member} />
      <Route path={`${path}/group`} component={Group} />
      <Route path={`${path}/topic`} component={Topic} />
      <Route path={`${path}/event`} component={Event} />
      <Route path={`${path}/signup`} component={Signup} />
      <Route exact path={`${path}/`} component={OrgGet} />
    </Switch>
  );
};

export default Org;
